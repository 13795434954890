import { useEffect, useState, useContext } from 'react';
import { getprojectsfromdate } from '../modules/api';
import stc from 'string-to-color';
import moment from 'moment';
import {
  Calendar,
  Button,
  Typography,
  Space,
  Badge,
  Spin,
  Tooltip,
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { Context } from '../utils/Context';
const { Title } = Typography;
const CalendarPage = () => {
  let navigate = useNavigate();
  let params = useParams();
  const [actualDate, setActualDate] = useState();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const context = useContext(Context);
  const { setHeaderType } = context;
  const getdata = async (date) => {
    setLoading(true);
    let firstdayofweekofmonth = date
      .clone()
      .date(1)
      .subtract(date.clone().date(1).day(), 'days');
    let lastdayofweekofmonth = firstdayofweekofmonth
      .clone()
      .add(6, 'weeks')
      .subtract(1, 'days');
    let response = await getprojectsfromdate(
      firstdayofweekofmonth.format('YYYYMMDD'),
      lastdayofweekofmonth.format('YYYYMMDD')
    );
    // response.sort((a, b) => {
    //   return a.NoProjet.localeCompare(b.NoProjet);
    // });
    console.log(response);
    setData(response);

    setLoading(false);
  };
  useEffect(() => {
    setHeaderType('calendar');
  }, []);
  useEffect(() => {
    if (!params.date) {
      let today = moment();
      navigate('/calendrier/' + today.format('YYYYMMDD'));
      setActualDate(today);
    } else {
      let date = moment(params.date);
      getdata(date);
      setActualDate(date);
    }
  }, [params.date]);
  const onPanelChange = (value) => {
    navigate('/calendrier/' + value.format('YYYYMMDD'));
  };
  const dateFullCellRender = (value) => {
    return (
      <div
        className={`ant-picker-cell-inner ant-picker-calendar-date ${
          value.startOf('day').diff(moment().startOf('day')) === 0 &&
          ' ant-picker-calendar-date-today'
        }`}
        onDoubleClick={() => {
          navigate('/planification/' + value.format('YYYYMMDD'));
        }}>
        <div className="ant-picker-calendar-date-value">
          {value.format('DD')}
        </div>
        <div className="ant-picker-calendar-date-content">
          <ul className="events">
            {data[value.format('YYYYMMDD')]?.map((item, index) => {
              return (
                <li key={value.format('YYYYMMDD') + item.KpProjPhase + index}>
                  <Tooltip
                    title={() => {
                      return (
                        <div>
                          <div>{item.NoProjet}</div>
                          <div>{item.DsProjet}</div>
                          <div>{item.DsPhase}</div>
                        </div>
                      );
                    }}
                    placement="right"
                    color={stc(item.NoProjet)}>
                    <Badge
                      color={stc(item.NoProjet)}
                      text={item.NoProjet + '-' + item.DsProjet}
                    />
                  </Tooltip>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  };
  return (
    <div className="CalendarPage">
      <Spin spinning={loading} style={{ height: '100%' }}>
        <Calendar
          value={actualDate}
          onPanelChange={onPanelChange}
          onSelect={(date) => {
            setActualDate(date);
          }}
          dateFullCellRender={dateFullCellRender}
          headerRender={() => {
            return null;
          }}
        />
      </Spin>
    </div>
  );
};

export default CalendarPage;
