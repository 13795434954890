import { Space, Form, Input, Button, Spin, Dropdown, Menu } from "antd";
import { useEffect } from "react";
import { CheckOutlined, PlusOutlined } from "@ant-design/icons";
import MarquageCourteDuree from "./Peinture/MarquageCourteDuree";
import MarquageMoyenneDuree from "./Peinture/MarquageMoyenneDuree";
import MMA from "./Peinture/MMA";
import MarquageLongueDuree from "./Peinture/MarquageLongueDuree";
import Gabarits from "./Peinture/Gabarits";

const Inventaire = ({
  loading,
  feuilleDeRoute,
  feuilleDeRouteQuantites,
  feuilleDeRoutePeinture,
  saveFeuilleDeRouteData,
  peintureSaved,
}) => {
  useEffect(() => {
    form.setFieldsValue(feuilleDeRoutePeinture);
  }, [feuilleDeRoutePeinture]);
  const onFinish = (values) => {
    console.log("Success:", values);
    saveFeuilleDeRouteData("peinture", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();
  const addMenu = (
    <Menu>
      <Menu.Item key="courteduree" onClick={() => {}}>
        Marquage courte durée
      </Menu.Item>
      <Menu.Item key="moyenneduree" onClick={() => {}}>
        Marquage moyenne durée
      </Menu.Item>
      <Menu.Item key="longueduree" onClick={() => {}}>
        Marquage longue durée
      </Menu.Item>
    </Menu>
  );
  return (
    <Form
      layout="vertical"
      scrollToFirstError={{ behavior: "smooth" }}
      form={form}
      disabled={feuilleDeRoute.status !== "complete"}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      onFieldsChange={(e) => {
        saveFeuilleDeRouteData("peinture", null);
      }}
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}>
      {loading && (
        <Spin
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 20,
          }}
        />
      )}
      {/* <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-end',
          marginBottom: 10,
        }}>
        <Dropdown overlay={addMenu} trigger={['click']}>
          <Button icon={<PlusOutlined />} type="primary" />
        </Dropdown>
      </div> */}
      <Space direction="vertical" style={{ width: "100%" }}>
        <Gabarits />
        {feuilleDeRouteQuantites?.marquagecourteduree && (
          <MarquageCourteDuree
            form={form}
            disabled={feuilleDeRoute.status === "approved"}
            feuilleDeRoutePeinture={feuilleDeRoutePeinture}
          />
        )}
        {feuilleDeRouteQuantites?.marquagemoyenneduree && (
          <MarquageMoyenneDuree
            form={form}
            disabled={feuilleDeRoute.status === "approved"}
            // feuilleDeRoutePeinture={feuilleDeRoutePeinture}
          />
        )}
        {feuilleDeRouteQuantites?.mma && <MMA />}
        {/* <MarquageLongueDuree /> */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 10,
          }}>
          <Form.Item noStyle style={{ textAlign: "center" }}>
            <Button
              type="primary"
              htmlType="submit"
              disabled={feuilleDeRoute.status !== "complete"}
              icon={peintureSaved && <CheckOutlined />}
              style={{
                backgroundColor: peintureSaved && "green",
                borderColor: peintureSaved && "green",
              }}>
              {peintureSaved ? "Enregistré" : "Enregistrer"}
            </Button>
          </Form.Item>
        </div>
      </Space>
    </Form>
  );
};

export default Inventaire;
