import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const getToken = () => {
  const token = cookies.get("token") || "";
  return token;
};

export const getprojectsfromdate = async (from, to) => {
  const token = getToken();
  try {
    let response = await axios.get(
      `/api/getprojectsfromdate/?from=${from}&to=${to}`,
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
    return response.data;
  } catch (err) {
    if (err.response.status === 401) {
      window.location.href = "/login";
    }
  }
};

export const getplaniffromdate = async (date) => {
  const token = getToken();
  try {
    let response = await axios.get(
      `/api/getprojectsphasesfromdate/?date=${date}`,
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
    return response.data;
  } catch (err) {
    if (err.response.status === 401) {
      window.location.href = "/login";
    }
  }
};
export const getphasefromdateandid = async (date, phaseid) => {
  const token = getToken();
  try {
    let response = await axios.get(
      `/api/getphasefromdateandid/?date=${date}&phaseid=${phaseid}`,
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
    return response.data;
  } catch (err) {
    if (err.response.status === 401) {
      window.location.href = "/login";
    }
  }
};
export const getcommandeventefromproject = async (project) => {
  const token = getToken();
  try {
    let response = await axios.get(
      `/api/getcommandeventefromproject/?project=${project}`,
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
    return response.data;
  } catch (err) {
    if (err.response.status === 401) {
      window.location.href = "/login";
    }
  }
};
export const getclientsfromnoproject = async (projects) => {
  const token = getToken();
  try {
    let response = await axios.get(
      `/api/getclientsfromnoproject/?projects=${projects}`,
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
    return response.data;
  } catch (err) {
    if (err.response.status === 401) {
      window.location.href = "/login";
    }
  }
};
export const getnodonneur = async (project) => {
  const token = getToken();
  try {
    let response = await axios.get(`/api/getnodonneur/?project=${project}`, {
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  } catch (err) {
    if (err.response.status === 401) {
      window.location.href = "/login";
    }
  }
};
export const savefeuillederoute = async (
  id,
  contremaitre,
  date,
  phase,
  data,
  noprojet,
  descriptionprojet,
  descriptionphase,
  client,
  chargedeprojet,
  employes,
  vehicules,
  localisation,
  nomclient,
  numeroclient,
  heurearriveechantier,
  commentaires,
  fichiers
) => {
  const token = getToken();
  const cleanFichiers = fichiers
    .filter((e) => e.checked)
    .map((e) => {
      return { name: e.name, url: e.url };
    });
  try {
    let response = await axios.post(
      "/db/savefeuillederoute/",
      {
        id,
        contremaitre,
        date,
        phase,
        data,
        noprojet,
        descriptionprojet,
        descriptionphase,
        client,
        chargedeprojet,
        employes,
        vehicules,
        localisation,
        nomclient,
        numeroclient,
        heurearriveechantier,
        commentaires,
        fichiers: cleanFichiers,
      },
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
    return response.data;
  } catch (err) {
    if (err.response.status === 401) {
      window.location.href = "/login";
    }
  }
};
export const deletefeuillederoute = async (id) => {
  const token = getToken();
  try {
    let response = await axios.get(`/db/deletefeuillederoute/?id=${id}`, {
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  } catch (err) {
    if (err.response.status === 401) {
      window.location.href = "/login";
    }
  }
};
export const getfeuillederoute = async (id) => {
  const token = getToken();
  try {
    let response = await axios.get(`/db/getfeuillederoute/?id=${id}`, {
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  } catch (err) {
    if (err.response.status === 401) {
      window.location.href = "/login";
    }
  }
};
export const getfeuillesderoute = async (date) => {
  const token = getToken();
  try {
    let response = await axios.get(`/db/getfeuillesderoute?date=${date}`, {
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  } catch (err) {
    if (err.response.status === 401) {
      window.location.href = "/login";
    }
  }
};
export const getallfeuillesderoute = async (quantity, page) => {
  const token = getToken();
  try {
    let response = await axios.get(
      `/db/getallfeuillesderoute?quantity=${quantity}&page=${page}`,
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
    return response.data;
  } catch (err) {
    if (err.response.status === 401) {
      window.location.href = "/login";
    }
  }
};
export const getfilteredfeuillesderoute = async (quantity, page, filter) => {
  const token = getToken();
  try {
    let response = await axios.get(
      `/db/getfilteredfeuillesderoute?quantity=${quantity}&page=${page}&filter=${filter}`,
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
    return response.data;
  } catch (err) {
    if (err.response.status === 401) {
      window.location.href = "/login";
    }
  }
};
export const getsearchedfeuillesderoute = async (quantity, page, search) => {
  const token = getToken();
  try {
    let response = await axios.get(
      `/db/getsearchedfeuillesderoute?quantity=${quantity}&page=${page}&search=${search}`,
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
    return response.data;
  } catch (err) {
    if (err.response.status === 401) {
      window.location.href = "/login";
    }
  }
};
export const sendfeuillederoute = async (data) => {
  const token = getToken();
  try {
    let response = await axios.post(
      "/db/sendfeuillederoute/",
      {
        data,
      },
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
    return response.data;
  } catch (err) {
    if (err.response.status === 401) {
      window.location.href = "/login";
    }
  }
};
export const changestatusfeuillederoute = async ({ id, status }) => {
  const token = getToken();
  try {
    let response = await axios.post(
      "/db/changestatusfeuillederoute/",
      {
        id,
        status,
      },
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
    return response.data;
  } catch (err) {
    if (err.response.status === 401) {
      window.location.href = "/login";
    }
  }
};
export const getemployeslist = async () => {
  const token = getToken();
  try {
    let response = await axios.get("/api/getemployes", {
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  } catch (err) {
    if (err.response.status === 401) {
      window.location.href = "/login";
    }
  }
};

export const getdbfields = async () => {
  const token = getToken();
  try {
    let response = await axios.get("/db/getallfields", {
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  } catch (err) {
    if (err.response.status === 401) {
      window.location.href = "/login";
    }
  }
};

export const getrapportfeuillesderoute = async (data) => {
  const token = getToken();
  try {
    let response = await axios.post("/db/getrapportfeuillesderoute", data, {
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  } catch (err) {
    if (err.response.status === 401) {
      window.location.href = "/login";
    }
  }
};
export const getdbfieldsheures = async () => {
  const token = getToken();
  try {
    let response = await axios.get("/db/getallfieldsheures", {
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  } catch (err) {
    if (err.response.status === 401) {
      window.location.href = "/login";
    }
  }
};
export const getheures = async (data) => {
  const token = getToken();
  try {
    let response = await axios.post("/db/getheures", data, {
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  } catch (err) {
    if (err.response.status === 401) {
      window.location.href = "/login";
    }
  }
};
