import { Card, Form, Input, Space, Button, InputNumber, Collapse } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
const { Panel } = Collapse;
const MarquageLongueDuree = ({
  feuilleDeRoute,
  feuilleDeRouteQuantites,
  setFeuilleDeRouteQuantites,
}) => {
  return (
    <Card
      title="Marquage Longue Durée"
      headStyle={{
        backgroundColor: '#001529',
        height: 50,
        display: 'flex',
        alignItems: 'center',
        color: 'white',
      }}
      bodyStyle={{
        paddingTop: 15,
        paddingBottom: 15,
        display: 'flex',
        flexDirection: 'column',
      }}>
      <Collapse>
        <Panel header="Réservoir" key="1">
          <div style={{ display: 'flex' }}>
            <Form.List
              name="reservoirjaune"
              initialValue={[{ start: '', finish: '' }]}>
              {(fields, { add, remove }, { errors }) => (
                <Card
                  title="Jaune"
                  extra={
                    <Button
                      icon={<PlusOutlined />}
                      type="primary"
                      onClick={() => add()}
                    />
                  }
                  style={{ flex: 1, marginLeft: 10, marginRight: 10 }}
                  headStyle={{
                    backgroundColor: '#fcba03',
                    color: 'white',
                  }}
                  bodyStyle={{
                    padding: 1,
                  }}>
                  {fields.map((field, index) => (
                    <Card
                      title={`Réservoir #${index + 1}`}
                      extra={
                        index >= 1 && (
                          <Button
                            icon={<MinusOutlined />}
                            type="danger"
                            onClick={() => remove(field.name)}
                          />
                        )
                      }
                      bordered={false}
                      key={field.key}>
                      <Form.Item
                        label="Niveau départ"
                        name={[field.name, 'start']}>
                        <InputNumber style={{ width: '100%' }} />
                      </Form.Item>
                      <Form.Item
                        label="Niveau arrivée"
                        // name={[`${categorie}${articleIndex}`, 'quantiteest']}
                        name={[field.name, 'finish']}>
                        <InputNumber style={{ width: '100%' }} />
                      </Form.Item>
                    </Card>
                  ))}
                </Card>
              )}
            </Form.List>
            <Form.List
              name="reservoirblanc"
              initialValue={[{ start: '', finish: '' }]}>
              {(fields, { add, remove }, { errors }) => (
                <Card
                  title="Blanc"
                  extra={
                    <Button
                      icon={<PlusOutlined />}
                      type="primary"
                      onClick={() => add()}
                    />
                  }
                  style={{ flex: 1, marginLeft: 10, marginRight: 10 }}
                  headStyle={{
                    backgroundColor: '#ffffff',
                    color: 'black',
                  }}
                  bodyStyle={{
                    padding: 1,
                  }}>
                  {fields.map((field, index) => (
                    <Card
                      title={`Réservoir #${index + 1}`}
                      extra={
                        index >= 1 && (
                          <Button
                            icon={<MinusOutlined />}
                            type="danger"
                            onClick={() => remove(field.name)}
                          />
                        )
                      }
                      bordered={false}
                      key={field.key}>
                      <Form.Item
                        label="Niveau départ"
                        name={[field.name, 'start']}>
                        <InputNumber style={{ width: '100%' }} />
                      </Form.Item>
                      <Form.Item
                        label="Niveau arrivée"
                        name={[field.name, 'finish']}>
                        <InputNumber style={{ width: '100%' }} />
                      </Form.Item>
                    </Card>
                  ))}
                </Card>
              )}
            </Form.List>
            <Form.List
              name="reservoirbille"
              initialValue={[{ start: '', finish: '' }]}>
              {(fields, { add, remove }, { errors }) => (
                <Card
                  title="Bille"
                  extra={
                    <Button
                      icon={<PlusOutlined />}
                      type="primary"
                      onClick={() => add()}
                    />
                  }
                  style={{ flex: 1, marginLeft: 10, marginRight: 10 }}
                  headStyle={{
                    backgroundColor: '#94928d',
                    color: 'white',
                  }}
                  bodyStyle={{
                    padding: 1,
                  }}>
                  {fields.map((field, index) => (
                    <Card
                      title={`Réservoir #${index + 1}`}
                      extra={
                        index >= 1 && (
                          <Button
                            icon={<MinusOutlined />}
                            type="danger"
                            onClick={() => remove(field.name)}
                          />
                        )
                      }
                      bordered={false}
                      key={field.key}>
                      <Form.Item
                        label="Niveau départ"
                        name={[field.name, 'start']}>
                        <InputNumber style={{ width: '100%' }} />
                      </Form.Item>
                      <Form.Item
                        label="Niveau arrivée"
                        name={[field.name, 'finish']}>
                        <InputNumber style={{ width: '100%' }} />
                      </Form.Item>
                    </Card>
                  ))}
                </Card>
              )}
            </Form.List>
          </div>
        </Panel>
        <Panel header="Barril" key="2">
          <div style={{ display: 'flex' }}>
            <Form.List
              name="barriljaune"
              initialValue={[{ start: '', finish: '' }]}>
              {(fields, { add, remove }, { errors }) => (
                <Card
                  title="Jaune"
                  extra={
                    <Button
                      icon={<PlusOutlined />}
                      type="primary"
                      onClick={() => add()}
                    />
                  }
                  style={{ flex: 1, marginLeft: 10, marginRight: 10 }}
                  headStyle={{
                    backgroundColor: '#fcba03',
                    color: 'white',
                  }}
                  bodyStyle={{
                    padding: 1,
                  }}>
                  {fields.map((field, index) => (
                    <Card
                      title={`Barril #${index + 1}`}
                      extra={
                        index >= 1 && (
                          <Button
                            icon={<MinusOutlined />}
                            type="danger"
                            onClick={() => remove(field.name)}
                          />
                        )
                      }
                      bordered={false}
                      key={field.key}>
                      <Form.Item
                        label="Niveau départ"
                        name={[field.name, 'start']}>
                        <InputNumber style={{ width: '100%' }} />
                      </Form.Item>
                      <Form.Item
                        label="Niveau arrivée"
                        // name={[`${categorie}${articleIndex}`, 'quantiteest']}
                        name={[field.name, 'finish']}>
                        <InputNumber style={{ width: '100%' }} />
                      </Form.Item>
                    </Card>
                  ))}
                </Card>
              )}
            </Form.List>
            <Form.List
              name="barrilblanc"
              initialValue={[{ start: '', finish: '' }]}>
              {(fields, { add, remove }, { errors }) => (
                <Card
                  title="Blanc"
                  extra={
                    <Button
                      icon={<PlusOutlined />}
                      type="primary"
                      onClick={() => add()}
                    />
                  }
                  style={{ flex: 1, marginLeft: 10, marginRight: 10 }}
                  headStyle={{
                    backgroundColor: '#ffffff',
                    color: 'black',
                  }}
                  bodyStyle={{
                    padding: 1,
                  }}>
                  {fields.map((field, index) => (
                    <Card
                      title={`Barril #${index + 1}`}
                      extra={
                        index >= 1 && (
                          <Button
                            icon={<MinusOutlined />}
                            type="danger"
                            onClick={() => remove(field.name)}
                          />
                        )
                      }
                      bordered={false}
                      key={field.key}>
                      <Form.Item
                        label="Niveau départ"
                        name={[field.name, 'start']}>
                        <InputNumber style={{ width: '100%' }} />
                      </Form.Item>
                      <Form.Item
                        label="Niveau arrivée"
                        name={[field.name, 'finish']}>
                        <InputNumber style={{ width: '100%' }} />
                      </Form.Item>
                    </Card>
                  ))}
                </Card>
              )}
            </Form.List>
            <Form.List
              name="barrilbille"
              initialValue={[{ start: '', finish: '' }]}>
              {(fields, { add, remove }, { errors }) => (
                <Card
                  title="Bille"
                  extra={
                    <Button
                      icon={<PlusOutlined />}
                      type="primary"
                      onClick={() => add()}
                    />
                  }
                  style={{ flex: 1, marginLeft: 10, marginRight: 10 }}
                  headStyle={{
                    backgroundColor: '#94928d',
                    color: 'white',
                  }}
                  bodyStyle={{
                    padding: 1,
                  }}>
                  {fields.map((field, index) => (
                    <Card
                      title={`Barril #${index + 1}`}
                      extra={
                        index >= 1 && (
                          <Button
                            icon={<MinusOutlined />}
                            type="danger"
                            onClick={() => remove(field.name)}
                          />
                        )
                      }
                      bordered={false}
                      key={field.key}>
                      <Form.Item
                        label="Niveau départ"
                        name={[field.name, 'start']}>
                        <InputNumber style={{ width: '100%' }} />
                      </Form.Item>
                      <Form.Item
                        label="Niveau arrivée"
                        name={[field.name, 'finish']}>
                        <InputNumber style={{ width: '100%' }} />
                      </Form.Item>
                    </Card>
                  ))}
                </Card>
              )}
            </Form.List>
          </div>
        </Panel>
        <Panel header="Chaudière/Sac" key="3">
          <div style={{ display: 'flex' }}>
            <Card
              title="Jaune"
              style={{ flex: 1, marginLeft: 10, marginRight: 10 }}
              headStyle={{
                backgroundColor: '#fcba03',
                color: 'white',
              }}>
              <Form.Item noStyle name="chaudierejaune">
                <Form.Item label="Nb départ" name="start">
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item label="Nb arrivée" name="finish">
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item label="Total" name="total">
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
              </Form.Item>
            </Card>
            <Card
              title="Blanc"
              style={{ flex: 1, marginLeft: 10, marginRight: 10 }}
              headStyle={{
                backgroundColor: '#ffffff',
                color: 'black',
              }}>
              <Form.Item label="Niveau départ" name="start">
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item label="Niveau arrivée" name="finish">
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item label="Total" name="finish">
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Card>

            <Card
              title="Bille"
              style={{ flex: 1, marginLeft: 10, marginRight: 10 }}
              headStyle={{
                backgroundColor: '#94928d',
                color: 'white',
              }}>
              <Form.Item label="Nb départ" name="start">
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item label="Nb arrivée" name="finish">
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item label="Total" name="total">
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Card>
          </div>
        </Panel>
      </Collapse>
    </Card>
  );
};

export default MarquageLongueDuree;
