import { UploadOutlined } from "@ant-design/icons";
import { Button, message, Upload, Spin, Checkbox } from "antd";
import { useEffect, useState, useRef } from "react";
import { Dropbox } from "dropbox";

const UPLOAD_FILE_SIZE_LIMIT = 150 * 1024 * 1024;
const ACCESS_TOKEN =
  "sl.BUG_4Qb2jNfMgd994mhQ5Bfxml1W32R8WqjCjYfPorTyw2RHyCSTH2zOldMZrsnYru6MqqKhd8ntm1dmbnMUdZZBVbnjk15U7Bgc6IYtAQwLUs0vD-7Ft5apunXC4vAobfe4CeIrthms";
const dbx = new Dropbox({
  // accessToken: ACCESS_TOKEN,
  clientId: "mkalsqcz9p2jvsd",
  clientSecret: "b9juaeo5pg18n1m",
  refreshToken:
    "n2T14aP4b_0AAAAAAAAAAV7cQAu7gIEZH0EoX5RRTG5ZmzRTzxqCzxsvpH545Pnr",
});

const UploadFile = ({
  data,
  informations,
  setInformations,
  selectedFichiers,
}) => {
  const [fileList, setFileList] = useState([]);
  const [loadingFiles, setLoadingFiles] = useState(false);
  useEffect(() => {
    getExistingFiles();
  }, [data, selectedFichiers]);
  const getExistingFiles = async () => {
    if (!data.NoProjet || !data.DsProjet) return;
    setLoadingFiles(true);
    try {
      let pathFolder = "/" + data.NoProjet;

      console.log({ pathFolder });
      const allFiles = (await dbx.filesListFolder({ path: pathFolder })).result
        .entries;
      const files = (
        await Promise.all(
          allFiles.map((file) => {
            return dbx.sharingListSharedLinks({ path: file.path_display });
          })
        )
      )
        .map((e) => {
          return {
            url: e.result.links[0]?.url.replace(
              "www.dropbox.com",
              "dl.dropboxusercontent.com"
            ),
            name: e.result.links[0]?.name,
            uid: e.result.links[0]?.id,
            status: "done",
            checked:
              selectedFichiers.filter(
                (f) =>
                  f.url ===
                  e.result.links[0]?.url.replace(
                    "www.dropbox.com",
                    "dl.dropboxusercontent.com"
                  )
              ).length > 0,
          };
        })
        .filter((e) => e.url !== undefined);
      setInformations("fichiers", [...files]);
      setFileList([...files]);
    } catch (err) {
      setInformations("fichiers", []);
      setFileList([]);
      console.error({ err });
    }
    setLoadingFiles(false);
  };
  const upload = async (file, path) => {
    if (file.size < UPLOAD_FILE_SIZE_LIMIT) {
      // File is smaller than 150 Mb - use filesUpload API
      try {
        await dbx.filesUpload({ path, contents: file });
        let sharedLink = (
          await dbx.sharingListSharedLinks({ path })
        ).result.links[0]?.url.replace(
          "www.dropbox.com",
          "dl.dropboxusercontent.com"
        );
        if (!sharedLink) {
          sharedLink = (
            await dbx.sharingCreateSharedLinkWithSettings({ path })
          ).result.url.replace("www.dropbox.com", "dl.dropboxusercontent.com");
        }
        const index = informations.current.fichiers.indexOf(file);
        informations.current.fichiers[index].status = "done";
        informations.current.fichiers[index].url = sharedLink;
        setInformations("fichiers", cleanFileList());
        setFileList([...informations.current.fichiers]);
      } catch (err) {
        console.error(err);
        const index = informations.current.fichiers.indexOf(file);
        informations.current.fichiers[index].status = "error";
        setFileList([...informations.current.fichiers]);
      }
    }
  };
  const cleanFileList = () => {
    return informations.current.fichiers.filter((e, index) => {
      return (
        index ===
        informations.current.fichiers.indexOf(
          informations.current.fichiers.filter((d) => d.url === e.url)[0]
        )
      );
    });
  };
  return (
    <>
      <Upload.Dragger
        multiple
        onRemove={async (file) => {
          const index = informations.current.fichiers.indexOf(file);
          informations.current.fichiers[index].status = "error";
          setFileList([...informations.current.fichiers]);
          const path = "/" + data.NoProjet + "/" + file.name;
          await dbx.filesDeleteV2({ path });
          setInformations(
            "fichiers",
            informations.current.fichiers.filter((e) => e.url !== file.url)
          );
          setFileList([...informations.current.fichiers]);
        }}
        beforeUpload={async (file) => {
          const path = "/" + data.NoProjet + "/" + file.name;

          file.status = "uploading";
          informations.current.fichiers.push(file);
          setFileList([...informations.current.fichiers]);

          upload(file, path);
          return false;
        }}
        fileList={fileList}
        iconRender={(file) => {
          if (file.status === "uploading") return <Spin />;
          if (file.status === "done") {
            const index = fileList.findIndex((e) => e.url === file.url);
            return (
              <Checkbox
                checked={fileList[index].checked}
                onChange={(e) => {
                  informations.current.fichiers[index].checked =
                    e.target.checked;
                  setFileList([...informations.current.fichiers]);
                }}
              />
            );
          }
          return null;
        }}
      >
        <div style={{ marginBottom: 10 }}>Glisser les fichiers, ou</div>
        <Button icon={<UploadOutlined />}>Cliquez ici</Button>
      </Upload.Dragger>
      {loadingFiles && <Spin style={{ marginLeft: 20, marginTop: 10 }} />}
    </>
  );
};

export default UploadFile;
