const Ressource = ({ title, icon, rightIcon }) => {
  return (
    <div style={{ display: 'flex' }}>
      {icon && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginRight: 5,
          }}>
          {icon}
        </div>
      )}
      <div>{title}</div>
      {rightIcon && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: 5,
          }}>
          {rightIcon}
        </div>
      )}
    </div>
  );
};

export default Ressource;
