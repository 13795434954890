import { useState, createContext, useEffect, useRef } from 'react';
import { notification } from 'antd';
import { getUser } from '../modules/users';
export const Context = createContext();

export default function ContextProvider({ children }) {
  const [vh, setVh] = useState(window.visualViewport.height * 0.01);
  const [headerType, setHeaderType] = useState(null);
  const [headerTitle, setHeaderTitle] = useState(null);
  const [headerLoading, setHeaderLoading] = useState(false);
  const [bottomMenuType, setBottomMenuType] = useState(null);
  const [bottomMenuValue, setBottomMenuValue] = useState(null);
  const [loggedUser, setLoggedUser] = useState(undefined);
  const getContextUser = async () => {
    try {
      const response = await getUser();
      setLoggedUser(response.user);
    } catch (err) {
      console.log({ err });
      if (err.response.status === 401) {
        setLoggedUser(undefined);
        if (!window.location.pathname.startsWith('/login')) {
          window.location.href = `/login?ref=${encodeURI(
            window.location.pathname
          )}`;
        }
      }
    }
  };
  const successNotif = (message) => {
    notification.success({
      message: message,
      placement: 'top',
    });
  };
  const errorNotif = (message) => {
    notification.error({
      message: message,
      placement: 'top',
    });
  };
  return (
    <Context.Provider
      value={{
        vh,
        setVh,
        bottomMenuType,
        setBottomMenuType,
        bottomMenuValue,
        setBottomMenuValue,
        headerType,
        setHeaderType,
        headerTitle,
        setHeaderTitle,
        headerLoading,
        setHeaderLoading,
        successNotif,
        errorNotif,
        getContextUser,
        loggedUser,
      }}>
      {children}
    </Context.Provider>
  );
}
