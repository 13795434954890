import {
  Card,
  Form,
  Input,
  Space,
  Button,
  InputNumber,
  Collapse,
  Row,
  Col,
  Select,
} from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { memo, useState, useEffect } from "react";
import quantiteData from "./quantiteData.json";
import { FaMagic } from "react-icons/fa";

const { Panel } = Collapse;

const MagicFill = ({ onClick, disabled }) => {
  return (
    <Button
      disabled={disabled}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 10,
      }}
      type="primary">
      <FaMagic />
    </Button>
  );
};

const MarquageCourteDuree = ({ form, feuilleDeRoutePeinture, disabled }) => {
  const [openedTab, setOpenedTab] = useState([]);
  const [camionReservoir, setCamionReservoir] = useState();
  const reservoirsList = Object.keys(quantiteData.cd.reservoir)
    .sort((a, b) => a.localeCompare(b))
    .map((camion) => ({ value: camion, label: camion }));

  useEffect(() => {
    let _openedTab = [];
    if (Object.keys(feuilleDeRoutePeinture || {}).length > 0) {
      if (
        [
          ...feuilleDeRoutePeinture.cd_reservoir_bille,
          ...feuilleDeRoutePeinture.cd_reservoir_blanc,
          ...feuilleDeRoutePeinture.cd_reservoir_jaune,
        ].length > 0
      ) {
        _openedTab.push("1");
      }
      if (
        [
          ...feuilleDeRoutePeinture.cd_barril_bille,
          ...feuilleDeRoutePeinture.cd_barril_blanc,
          ...feuilleDeRoutePeinture.cd_barril_jaune,
        ].length > 0
      ) {
        _openedTab.push("2");
      }
      if (
        feuilleDeRoutePeinture.cd_chaudiere_jaune_start ||
        feuilleDeRoutePeinture.cd_chaudiere_jaune_finish ||
        feuilleDeRoutePeinture.cd_chaudiere_jaune_total ||
        feuilleDeRoutePeinture.cd_chaudiere_blanc_start ||
        feuilleDeRoutePeinture.cd_chaudiere_blanc_finish ||
        feuilleDeRoutePeinture.cd_chaudiere_blanc_total ||
        feuilleDeRoutePeinture.cd_sac_bille_start ||
        feuilleDeRoutePeinture.cd_sac_bille_finish ||
        feuilleDeRoutePeinture.cd_sac_bille_total ||
        feuilleDeRoutePeinture.cd_chaudiere_couleurspeciale.length > 0
      ) {
        _openedTab.push("3");
      }
    }
    setOpenedTab(_openedTab);
  }, [feuilleDeRoutePeinture]);
  return (
    <Card
      title="Marquage Courte Durée"
      headStyle={{
        backgroundColor: "#001529",
        height: 50,
        display: "flex",
        alignItems: "center",
        color: "white",
      }}
      bodyStyle={{
        paddingTop: 15,
        paddingBottom: 15,
        display: "flex",
        flexDirection: "column",
      }}>
      <Collapse
        activeKey={openedTab}
        onChange={(e) => {
          if (disabled) return;
          setOpenedTab(e);
          if (!e.includes("1")) {
            form.resetFields([
              "cd_reservoir_jaune",
              "cd_reservoir_blanc",
              "cd_reservoir_bille",
            ]);
          }
          if (!e.includes("2")) {
            form.resetFields([
              "cd_barril_jaune",
              "cd_barril_blanc",
              "cd_barril_bille",
            ]);
          }
          if (!e.includes("3")) {
            form.resetFields([
              "cd_chaudiere_couleurspeciale",
              "cd_chaudiere_jaune_start",
              "cd_chaudiere_jaune_finish",
              "cd_chaudiere_jaune_total",
              "cd_chaudiere_blanc_start",
              "cd_chaudiere_blanc_finish",
              "cd_chaudiere_blanc_total",
              "cd_sac_bille_start",
              "cd_sac_bille_finish",
              "cd_sac_bille_total",
            ]);
          }
        }}>
        <Panel
          header={
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 15,
              }}>
              <span>Réservoir</span>
              <Space>
                <Select
                  placeholder="Choisir un camion"
                  allowClear
                  value={camionReservoir}
                  options={reservoirsList}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => {
                    setCamionReservoir(e);
                  }}
                  style={{ width: 175 }}
                />
                <MagicFill
                  disabled={
                    camionReservoir && openedTab.includes("1") ? false : true
                  }
                  onClick={() => {
                    const values = form.getFieldsValue();
                    let newReservoirJaune = [];
                    let newReservoirBlanc = [];
                    let newReservoirBille = [];
                    const multiplier =
                      quantiteData.cd.reservoir[camionReservoir];
                    values.cd_reservoir_jaune?.forEach((res) => {
                      const total =
                        res.finish >= res.start
                          ? (res.finish - res.start) * multiplier.peinture
                          : "erreur";
                      newReservoirJaune.push({
                        ...res,
                        total,
                      });
                    });
                    values.cd_reservoir_blanc?.forEach((res) => {
                      const total =
                        res.finish >= res.start
                          ? (res.finish - res.start) * multiplier.peinture
                          : "erreur";
                      newReservoirBlanc.push({
                        ...res,
                        total,
                      });
                    });
                    values.cd_reservoir_bille?.forEach((res) => {
                      const total =
                        res.finish >= res.start
                          ? (res.finish - res.start) * multiplier.bille
                          : "erreur";
                      newReservoirBille.push({
                        ...res,
                        total,
                      });
                    });
                    form.setFieldValue("cd_reservoir_jaune", newReservoirJaune);
                    form.setFieldValue("cd_reservoir_blanc", newReservoirBlanc);
                    form.setFieldValue("cd_reservoir_bille", newReservoirBille);
                  }}
                />
              </Space>
            </div>
          }
          key="1">
          <div style={{ display: "flex", gap: 15 }}>
            <Form.List name="cd_reservoir_jaune">
              {(fields, { add, remove }, { errors }) => (
                <Card
                  title="Jaune"
                  extra={
                    <Button
                      icon={<PlusOutlined />}
                      type="primary"
                      onClick={() => add()}
                    />
                  }
                  style={{ flex: 1 }}
                  headStyle={{
                    backgroundColor: "#fcba03",
                    color: "white",
                  }}
                  bodyStyle={{
                    padding: 1,
                  }}>
                  {fields.map((field, index) => (
                    <Card
                      title={`Réservoir #${index + 1}`}
                      extra={
                        <Button
                          icon={<MinusOutlined />}
                          type="danger"
                          onClick={() => remove(field.name)}
                        />
                      }
                      bordered={false}
                      key={field.key}>
                      <Form.Item
                        label="Niveau départ (pouces)"
                        name={[field.name, "start"]}
                        rules={[{ required: true, message: "À remplir" }]}>
                        <InputNumber style={{ width: "100%" }} min={0} />
                      </Form.Item>
                      <Form.Item
                        label="Niveau arrivée (pouces)"
                        name={[field.name, "finish"]}
                        rules={[{ required: true, message: "À remplir" }]}>
                        <InputNumber style={{ width: "100%" }} min={0} />
                      </Form.Item>
                      <Form.Item
                        label="Total (litres)"
                        name={[field.name, "total"]}
                        rules={[{ required: true, message: "À remplir" }]}>
                        <InputNumber style={{ width: "100%" }} min={0} />
                      </Form.Item>
                    </Card>
                  ))}
                </Card>
              )}
            </Form.List>
            <Form.List name="cd_reservoir_blanc">
              {(fields, { add, remove }, { errors }) => (
                <Card
                  title="Blanc"
                  extra={
                    <Button
                      icon={<PlusOutlined />}
                      type="primary"
                      onClick={() => add()}
                    />
                  }
                  style={{ flex: 1 }}
                  headStyle={{
                    backgroundColor: "#ffffff",
                    color: "black",
                  }}
                  bodyStyle={{
                    padding: 1,
                  }}>
                  {fields.map((field, index) => (
                    <Card
                      title={`Réservoir #${index + 1}`}
                      extra={
                        <Button
                          icon={<MinusOutlined />}
                          type="danger"
                          onClick={() => remove(field.name)}
                        />
                      }
                      bordered={false}
                      key={field.key}>
                      <Form.Item
                        label="Niveau départ (pouces)"
                        name={[field.name, "start"]}
                        rules={[{ required: true, message: "À remplir" }]}>
                        <InputNumber style={{ width: "100%" }} min={0} />
                      </Form.Item>
                      <Form.Item
                        label="Niveau arrivée (pouces)"
                        name={[field.name, "finish"]}
                        rules={[{ required: true, message: "À remplir" }]}>
                        <InputNumber style={{ width: "100%" }} min={0} />
                      </Form.Item>
                      <Form.Item
                        label="Total (litres)"
                        name={[field.name, "total"]}
                        rules={[{ required: true, message: "À remplir" }]}>
                        <InputNumber style={{ width: "100%" }} min={0} />
                      </Form.Item>
                    </Card>
                  ))}
                </Card>
              )}
            </Form.List>
            <Form.List name="cd_reservoir_bille">
              {(fields, { add, remove }, { errors }) => (
                <Card
                  title="Bille"
                  extra={
                    <Button
                      icon={<PlusOutlined />}
                      type="primary"
                      onClick={() => add()}
                    />
                  }
                  style={{ flex: 1 }}
                  headStyle={{
                    backgroundColor: "#94928d",
                    color: "white",
                  }}
                  bodyStyle={{
                    padding: 1,
                  }}>
                  {fields.map((field, index) => (
                    <Card
                      title={`Réservoir #${index + 1}`}
                      extra={
                        <Button
                          icon={<MinusOutlined />}
                          type="danger"
                          onClick={() => remove(field.name)}
                        />
                      }
                      bordered={false}
                      key={field.key}>
                      <Form.Item
                        label="Niveau départ (pouces)"
                        name={[field.name, "start"]}
                        rules={[{ required: true, message: "À remplir" }]}>
                        <InputNumber style={{ width: "100%" }} min={0} />
                      </Form.Item>
                      <Form.Item
                        label="Niveau arrivée (pouces)"
                        name={[field.name, "finish"]}
                        rules={[{ required: true, message: "À remplir" }]}>
                        <InputNumber style={{ width: "100%" }} min={0} />
                      </Form.Item>
                      <Form.Item
                        label="Total (litres)"
                        name={[field.name, "total"]}
                        rules={[{ required: true, message: "À remplir" }]}>
                        <InputNumber style={{ width: "100%" }} min={0} />
                      </Form.Item>
                    </Card>
                  ))}
                </Card>
              )}
            </Form.List>
          </div>
        </Panel>
        <Panel
          header={
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 15,
              }}>
              <span>Barril</span>
              <MagicFill
                disabled={!openedTab.includes("2")}
                onClick={() => {
                  const values = form.getFieldsValue();
                  let newBarrilJaune = [];
                  let newBarrilBlanc = [];
                  let newBarrilBille = [];
                  const multiplier = quantiteData.cd.barril;
                  values.cd_barril_jaune?.forEach((res) => {
                    const total =
                      res.finish >= res.start
                        ? (res.finish - res.start) * multiplier.peinture
                        : "erreur";
                    newBarrilJaune.push({
                      ...res,
                      total,
                    });
                  });
                  values.cd_barril_blanc?.forEach((res) => {
                    const total =
                      res.finish >= res.start
                        ? (res.finish - res.start) * multiplier.peinture
                        : "erreur";
                    newBarrilBlanc.push({
                      ...res,
                      total,
                    });
                  });
                  values.cd_barril_bille?.forEach((res) => {
                    const total =
                      res.finish >= res.start
                        ? (res.finish - res.start) * multiplier.bille
                        : "erreur";
                    newBarrilBille.push({
                      ...res,
                      total,
                    });
                  });
                  form.setFieldValue("cd_barril_jaune", newBarrilJaune);
                  form.setFieldValue("cd_barril_blanc", newBarrilBlanc);
                  form.setFieldValue("cd_barril_bille", newBarrilBille);
                }}
              />
            </div>
          }
          key="2">
          <div style={{ display: "flex", gap: 15 }}>
            <Form.List name="cd_barril_jaune">
              {(fields, { add, remove }, { errors }) => (
                <Card
                  title="Jaune"
                  extra={
                    <Button
                      icon={<PlusOutlined />}
                      type="primary"
                      onClick={() => add()}
                    />
                  }
                  style={{ flex: 1 }}
                  headStyle={{
                    backgroundColor: "#fcba03",
                    color: "white",
                  }}
                  bodyStyle={{
                    padding: 1,
                  }}>
                  {fields.map((field, index) => (
                    <Card
                      title={`Barril #${index + 1}`}
                      extra={
                        <Button
                          icon={<MinusOutlined />}
                          type="danger"
                          onClick={() => remove(field.name)}
                        />
                      }
                      bordered={false}
                      key={field.key}>
                      <Form.Item
                        label="Niveau départ (pouces)"
                        name={[field.name, "start"]}
                        rules={[{ required: true, message: "À remplir" }]}>
                        <InputNumber style={{ width: "100%" }} min={0} />
                      </Form.Item>
                      <Form.Item
                        label="Niveau arrivée (pouces)"
                        name={[field.name, "finish"]}
                        rules={[{ required: true, message: "À remplir" }]}>
                        <InputNumber style={{ width: "100%" }} min={0} />
                      </Form.Item>
                      <Form.Item
                        label="Total (litres)"
                        name={[field.name, "total"]}
                        rules={[{ required: true, message: "À remplir" }]}>
                        <InputNumber style={{ width: "100%" }} min={0} />
                      </Form.Item>
                    </Card>
                  ))}
                </Card>
              )}
            </Form.List>
            <Form.List name="cd_barril_blanc">
              {(fields, { add, remove }, { errors }) => (
                <Card
                  title="Blanc"
                  extra={
                    <Button
                      icon={<PlusOutlined />}
                      type="primary"
                      onClick={() => add()}
                    />
                  }
                  style={{ flex: 1 }}
                  headStyle={{
                    backgroundColor: "#ffffff",
                    color: "black",
                  }}
                  bodyStyle={{
                    padding: 1,
                  }}>
                  {fields.map((field, index) => (
                    <Card
                      title={`Barril #${index + 1}`}
                      extra={
                        <Button
                          icon={<MinusOutlined />}
                          type="danger"
                          onClick={() => remove(field.name)}
                        />
                      }
                      bordered={false}
                      key={field.key}>
                      <Form.Item
                        label="Niveau départ (pouces)"
                        name={[field.name, "start"]}
                        rules={[{ required: true, message: "À remplir" }]}>
                        <InputNumber style={{ width: "100%" }} min={0} />
                      </Form.Item>
                      <Form.Item
                        label="Niveau arrivée (pouces)"
                        name={[field.name, "finish"]}
                        rules={[{ required: true, message: "À remplir" }]}>
                        <InputNumber style={{ width: "100%" }} min={0} />
                      </Form.Item>
                      <Form.Item
                        label="Total (litres)"
                        name={[field.name, "total"]}
                        rules={[{ required: true, message: "À remplir" }]}>
                        <InputNumber style={{ width: "100%" }} min={0} />
                      </Form.Item>
                    </Card>
                  ))}
                </Card>
              )}
            </Form.List>
            <Form.List name="cd_barril_bille">
              {(fields, { add, remove }, { errors }) => (
                <Card
                  title="Bille"
                  extra={
                    <Button
                      icon={<PlusOutlined />}
                      type="primary"
                      onClick={() => add()}
                    />
                  }
                  style={{ flex: 1 }}
                  headStyle={{
                    backgroundColor: "#94928d",
                    color: "white",
                  }}
                  bodyStyle={{
                    padding: 1,
                  }}>
                  {fields.map((field, index) => (
                    <Card
                      title={`Barril #${index + 1}`}
                      extra={
                        <Button
                          icon={<MinusOutlined />}
                          type="danger"
                          onClick={() => remove(field.name)}
                        />
                      }
                      bordered={false}
                      key={field.key}>
                      <Form.Item
                        label="Niveau départ (pouces)"
                        name={[field.name, "start"]}
                        rules={[{ required: true, message: "À remplir" }]}>
                        <InputNumber style={{ width: "100%" }} min={0} />
                      </Form.Item>
                      <Form.Item
                        label="Niveau arrivée (pouces)"
                        name={[field.name, "finish"]}
                        rules={[{ required: true, message: "À remplir" }]}>
                        <InputNumber style={{ width: "100%" }} min={0} />
                      </Form.Item>
                      <Form.Item
                        label="Total (litres)"
                        name={[field.name, "total"]}
                        rules={[{ required: true, message: "À remplir" }]}>
                        <InputNumber style={{ width: "100%" }} min={0} />
                      </Form.Item>
                    </Card>
                  ))}
                </Card>
              )}
            </Form.List>
          </div>
        </Panel>
        <Panel
          header={
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 15,
              }}>
              <span>Chaudière</span>
              <MagicFill
                disabled={!openedTab.includes("3")}
                onClick={() => {
                  const values = form.getFieldsValue();
                  let newChaudiereCouleurSpeciale = [];
                  const multiplier = quantiteData.cd.chaudiere;
                  const totalJaune =
                    parseInt(values.cd_chaudiere_jaune_start) >=
                    parseInt(values.cd_chaudiere_jaune_finish)
                      ? (parseInt(values.cd_chaudiere_jaune_start) -
                          parseInt(values.cd_chaudiere_jaune_finish)) *
                        multiplier.peinture
                      : "erreur";
                  const totalBlanc =
                    parseInt(values.cd_chaudiere_blanc_start) >=
                    parseInt(values.cd_chaudiere_blanc_finish)
                      ? (parseInt(values.cd_chaudiere_blanc_start) -
                          parseInt(values.cd_chaudiere_blanc_finish)) *
                        multiplier.peinture
                      : "erreur";
                  const totalBille =
                    parseInt(values.cd_sac_bille_start) >=
                    parseInt(values.cd_sac_bille_finish)
                      ? (parseInt(values.cd_sac_bille_start) -
                          parseInt(values.cd_sac_bille_finish)) *
                        multiplier.bille
                      : "erreur";
                  values.cd_chaudiere_couleurspeciale?.forEach((res) => {
                    const total =
                      parseInt(res.start) >= parseInt(res.finish)
                        ? (parseInt(res.start) - parseInt(res.finish)) *
                          multiplier.peinture
                        : "erreur";
                    newChaudiereCouleurSpeciale.push({
                      ...res,
                      total,
                    });
                  });
                  form.setFieldValue("cd_chaudiere_jaune_total", totalJaune);
                  form.setFieldValue("cd_chaudiere_blanc_total", totalBlanc);
                  form.setFieldValue("cd_sac_bille_total", totalBille);
                  form.setFieldValue(
                    "cd_chaudiere_couleurspeciale",
                    newChaudiereCouleurSpeciale
                  );
                }}
              />
            </div>
          }
          key="3">
          <div style={{ display: "flex", marginBottom: 10, gap: 15 }}>
            <Card
              title="Jaune"
              style={{ flex: 1 }}
              headStyle={{
                backgroundColor: "#fcba03",
                color: "white",
                padding: "16px 24px",
              }}>
              <Form.Item label="Nb départ (u)" name="cd_chaudiere_jaune_start">
                <InputNumber style={{ width: "100%" }} min={0} />
              </Form.Item>
              <Form.Item
                label="Nb arrivée (u)"
                name="cd_chaudiere_jaune_finish">
                <InputNumber style={{ width: "100%" }} min={0} />
              </Form.Item>
              <Form.Item
                label="Total (litres)"
                name="cd_chaudiere_jaune_total"
                rules={[{ required: true, message: "À remplir" }]}>
                <InputNumber style={{ width: "100%" }} min={0} />
              </Form.Item>
            </Card>
            <Card
              title="Blanc"
              style={{ flex: 1 }}
              headStyle={{
                backgroundColor: "#ffffff",
                color: "black",
                padding: "16px 24px",
              }}>
              <Form.Item label="Nb départ (u)" name="cd_chaudiere_blanc_start">
                <InputNumber style={{ width: "100%" }} min={0} />
              </Form.Item>
              <Form.Item
                label="Nb arrivée (u)"
                name="cd_chaudiere_blanc_finish">
                <InputNumber style={{ width: "100%" }} min={0} />
              </Form.Item>
              <Form.Item
                label="Total (litres)"
                name="cd_chaudiere_blanc_total"
                rules={[{ required: true, message: "À remplir" }]}>
                <InputNumber style={{ width: "100%" }} min={0} />
              </Form.Item>
            </Card>
            <Card
              title="Bille (sacs)"
              style={{ flex: 1 }}
              headStyle={{
                backgroundColor: "#94928d",
                color: "white",
                padding: "16px 24px",
              }}>
              <Form.Item label="Nb départ (u)" name="cd_sac_bille_start">
                <Input />
              </Form.Item>
              <Form.Item label="Nb arrivée (u)" name="cd_sac_bille_finish">
                <Input />
              </Form.Item>
              <Form.Item
                label="Total (kg)"
                name="cd_sac_bille_total"
                rules={[{ required: true, message: "À remplir" }]}>
                <Input />
              </Form.Item>
            </Card>
          </div>

          <Form.List name="cd_chaudiere_couleurspeciale">
            {(fields, { add, remove }, { errors }) => (
              <Card
                title="Couleur Spéciale"
                extra={
                  <Button
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() => add()}
                  />
                }
                headStyle={{
                  backgroundColor: "#54b86f",
                  color: "white",
                }}
                style={{ flex: 1 }}
                bodyStyle={{
                  padding: 0,
                }}>
                <Row>
                  {fields.map((field, index) => (
                    <Col span={8} gutter={[15, 15]} key={field.key}>
                      <Card
                        title={`Couleur #${index + 1}`}
                        extra={
                          <Button
                            icon={<MinusOutlined />}
                            type="danger"
                            onClick={() => remove(field.name)}
                          />
                        }
                        bordered={false}
                        key={field.key}>
                        <Form.Item
                          label="Couleur"
                          name={[field.name, "couleur"]}
                          rules={[{ required: true, message: "À remplir" }]}>
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Nb départ (u)"
                          name={[field.name, "start"]}
                          rules={[{ required: true, message: "À remplir" }]}>
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Nb arrivée (u)"
                          name={[field.name, "finish"]}
                          rules={[{ required: true, message: "À remplir" }]}>
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Total (litres)"
                          name={[field.name, "total"]}
                          rules={[{ required: true, message: "À remplir" }]}>
                          <Input />
                        </Form.Item>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Card>
            )}
          </Form.List>
        </Panel>
      </Collapse>
    </Card>
  );
};

export default memo(MarquageCourteDuree);
