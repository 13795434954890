import {
  Space,
  Form,
  Input,
  Checkbox,
  Button,
  Card,
  Spin,
  List,
  Row,
  Col,
  Switch,
  AutoComplete,
  Popconfirm,
} from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
const { TextArea } = Input;
var duration = require('dayjs/plugin/duration');
const CustomTimeInput = ({ ...restProps }) => {
  return (
    <Input
      type="time"
      {...restProps}
      style={{ width: '100%', height: 34 }}
      step="900"
    />
  );
};
const CustomFormItem = ({ name, type, employe, initialValue, form }) => {
  return (
    <Form.Item
      noStyle
      shouldUpdate={(prevValues, curValues) => {
        return (
          prevValues['employes'][employe._id]['absent'] !==
          curValues['employes'][employe._id]['absent']
        );
      }}>
      {({ getFieldValue, setFieldsValue, setFields }) => (
        <Form.Item
          // noStyle
          style={{ marginBottom: 0 }}
          rules={[
            {
              required:
                type === 'debut' || type === 'fin'
                  ? !form?.getFieldValue(['employes', employe._id, 'absent'])
                  : false,
              message: 'À remplir',
            },
          ]}
          name={name}
          initialValue={initialValue ? initialValue : ''}>
          <CustomTimeInput
            onChange={() => {
              if (type === 'debut' || type === 'fin' || type === 'repas') {
                const [debutHeure, debutMinutes] = getFieldValue([
                  'employes',
                  employe._id,
                  'debut',
                ]).split(':');
                const [finHeure, finMinutes] = getFieldValue([
                  'employes',
                  employe._id,
                  'fin',
                ]).split(':');
                const [repasHeure, repasMinutes] = getFieldValue([
                  'employes',
                  employe._id,
                  'repas',
                ]).split(':');
                if (!debutHeure || !debutMinutes || !finHeure || !finMinutes)
                  return;
                const debutDuration = dayjs.duration({
                  minutes: debutMinutes,
                  hours: debutHeure,
                });
                const finDuration = dayjs.duration({
                  minutes: finMinutes,
                  hours: finHeure,
                });
                const repasDuration = dayjs.duration({
                  minutes: repasMinutes,
                  hours: repasHeure,
                });
                // console.log({ debutDuration, finDuration, repasDuration });
                const shiftDuration =
                  finDuration.subtract(debutDuration).asMinutes() > 0
                    ? finDuration.subtract(debutDuration)
                    : finDuration.add(24, 'hours').subtract(debutDuration);
                const shiftDurationSansRepas =
                  repasDuration.asMinutes() > 0
                    ? shiftDuration.subtract(repasDuration)
                    : shiftDuration;
                setFields([
                  {
                    name: ['employes', employe._id, 'total'],
                    value: shiftDurationSansRepas.format('HH:mm'),
                  },
                ]);
              }
            }}
            disabled={getFieldValue(['employes', employe._id, 'absent'])}
          />
        </Form.Item>
      )}
    </Form.Item>
  );
};
const Ressources = ({
  loading,
  feuilleDeRoute,
  feuilleDeRouteVehicules,
  feuilleDeRouteMainDoeuvres,
  saveFeuilleDeRouteData,
  ressourcesSaved,
  employesList,
}) => {
  const [newEmployes, setNewEmployes] = useState([]);
  dayjs.extend(duration);
  let mainDoeuvre = [];
  Object.values(feuilleDeRouteMainDoeuvres).map((camion) =>
    camion.map((employe) => mainDoeuvre.push(employe))
  );
  mainDoeuvre.sort((a, b) => a.nom.localeCompare(b.nom));
  const options = employesList
    .map((employe) => {
      return {
        value: employe.Nom + ', ' + employe.Prénom,
        label: employe.Nom + ', ' + employe.Prénom,
      };
    })
    .sort((a, b) => a.value.localeCompare(b.value));
  const onFinish = (values) => {
    console.log('Success:', values);
    saveFeuilleDeRouteData('ressources', values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const handleAddMaindoeuvre = () => {
    const _newEmployes = [...newEmployes];
    _newEmployes.push({
      _id: 'new' + Date.now(),
      idfeuillederoute: feuilleDeRoute.id,
      new: true,
    });
    setNewEmployes(_newEmployes);
  };
  const resetLine = (employeid) => {
    form.setFields([
      {
        name: ['employes', employeid, 'debut'],
        value: '',
      },
      { name: ['employes', employeid, 'fin'], value: '' },
      { name: ['employes', employeid, 'repas'], value: '00:00' },
      { name: ['employes', employeid, 'total'], value: '' },
      { name: ['employes', employeid, 'perdiem'], value: '' },
    ]);
  };
  const [form] = Form.useForm();
  useEffect(() => {
    let feuilleDeRouteData = {};
    let employes = {};
    let vehicules = {};
    mainDoeuvre.map((employe) => (employes[employe._id] = employe));
    Object.values(feuilleDeRouteVehicules).map(
      (vehicule) => (vehicules[vehicule[0]._id] = vehicule[0])
    );
    feuilleDeRouteData.employes = employes;
    feuilleDeRouteData.vehicules = vehicules;
    form.setFieldsValue(feuilleDeRouteData);
  }, [feuilleDeRouteMainDoeuvres, feuilleDeRouteVehicules]);
  return (
    <Form
      layout="vertical"
      form={form}
      disabled={feuilleDeRoute.status !== 'complete'}
      scrollToFirstError={{ behavior: 'smooth' }}
      onFieldsChange={() => {
        saveFeuilleDeRouteData('ressources', null);
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      style={{ display: 'flex', justifyContent: 'center' }}>
      {!loading ? (
        <Space direction="vertical" style={{ width: '100%' }}>
          <Card
            title="Véhicules"
            headStyle={{
              backgroundColor: '#001529',
              width: '100%',
              height: 50,
              padding: '0px 24px',
              display: 'flex',
              alignItems: 'center',
              color: 'white',
            }}
            bodyStyle={{
              paddingTop: 15,
              paddingBottom: 15,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <List
              style={{ width: '100%' }}
              bordered
              header={
                <Row style={{ width: '100%', fontWeight: 'bold' }} gutter={15}>
                  <Col span={12}>Description</Col>
                  <Col span={12}>État</Col>
                </Row>
              }
              dataSource={Object.values(feuilleDeRouteVehicules).map(
                (array) => array[0]
              )}
              renderItem={(item) => {
                return (
                  <List.Item>
                    <Row style={{ width: '100%' }}>
                      <Col span={12}>{item.nom}</Col>
                      <Col span={12}>
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                          }}>
                          <Form.Item
                            noStyle
                            name={['vehicules', item._id, 'bonetat']}
                            valuePropName="checked">
                            <Switch
                              checkedChildren={<CheckOutlined />}
                              unCheckedChildren={<CloseOutlined />}
                            />
                          </Form.Item>
                          <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => {
                              if (!prevValues['vehicules'])
                                prevValues['vehicules'] = {};
                              if (!currentValues['vehicules'])
                                currentValues['vehicules'] = {};
                              return (
                                prevValues['vehicules'][item._id]?.bonetat !==
                                currentValues['vehicules'][item._id]?.bonetat
                              );
                            }}>
                            {({ getFieldValue }) =>
                              getFieldValue([
                                'vehicules',
                                item._id,
                                'bonetat',
                              ]) === false ? (
                                <Form.Item
                                  // noStyle
                                  style={{
                                    width: '100%',
                                    marginBottom: 0,
                                    textAlign: 'center',
                                  }}
                                  name={[
                                    'vehicules',
                                    item._id,
                                    'commentaireetat',
                                  ]}
                                  rules={[
                                    {
                                      required: true,
                                      whitespace: true,
                                      message: 'À remplir',
                                    },
                                  ]}>
                                  <TextArea
                                    style={{ marginLeft: 10 }}
                                    autoSize
                                  />
                                </Form.Item>
                              ) : null
                            }
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </List.Item>
                );
              }}
            />
          </Card>
          <Card
            title="Main D'oeuvre"
            headStyle={{
              backgroundColor: '#001529',
              width: '100%',
              height: 50,
              padding: '0px 24px',
              display: 'flex',
              alignItems: 'center',
              color: 'white',
            }}
            bodyStyle={{
              paddingTop: 15,
              paddingBottom: 15,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}>
            <List
              style={{ width: '100%' }}
              header={
                <Row style={{ width: '100%', fontWeight: 'bold' }} gutter={15}>
                  <Col span={8}>Nom</Col>
                  <Col span={2} style={{ textAlign: 'center' }}>
                    Perdiem
                  </Col>
                  <Col span={3} style={{ textAlign: 'center' }}>
                    Début
                  </Col>
                  <Col span={3} style={{ textAlign: 'center' }}>
                    Fin
                  </Col>
                  <Col span={3} style={{ textAlign: 'center' }}>
                    Repas
                  </Col>
                  <Col span={3} style={{ textAlign: 'center' }}>
                    Total
                  </Col>
                  <Col span={2} style={{ textAlign: 'center' }}>
                    Absent?
                  </Col>
                </Row>
              }
              bordered
              dataSource={[...mainDoeuvre, ...newEmployes]}
              renderItem={(employe) => {
                return (
                  <List.Item>
                    <Row style={{ width: '100%' }} gutter={15}>
                      <Col span={8}>
                        {employe.new && (
                          <Form.Item
                            name={['employes', employe._id, 'new']}
                            initialValue={true}
                            hidden>
                            <div />
                          </Form.Item>
                        )}
                        {employe.new ? (
                          <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, curValues) => {
                              return (
                                prevValues['employes'][employe._id][
                                  'absent'
                                ] !==
                                curValues['employes'][employe._id]['absent']
                              );
                            }}>
                            {({ getFieldValue }) => (
                              <Form.Item
                                style={{
                                  marginBottom: 0,
                                }}
                                initialValue={''}
                                rules={[
                                  {
                                    required: true,
                                    message: 'À remplir',
                                  },
                                ]}
                                name={['employes', employe._id, 'nom']}>
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 10,
                                  }}>
                                  <AutoComplete
                                    style={{ width: '100%' }}
                                    disabled={getFieldValue([
                                      'employes',
                                      employe._id,
                                      'absent',
                                    ])}
                                    options={options}
                                    defaultValue={form.getFieldValue([
                                      'employes',
                                      employe._id,
                                      'nom',
                                    ])}
                                    dropdownMatchSelectWidth={false}
                                    allowClear
                                    onChange={(e) => {
                                      form.setFields([
                                        {
                                          name: [
                                            'employes',
                                            employe._id,
                                            'nom',
                                          ],
                                          value: e,
                                        },
                                      ]);
                                    }}
                                    filterOption={(inputValue, option) => {
                                      if (!option.value) return false;
                                      return (
                                        option.value
                                          .toUpperCase()
                                          .indexOf(inputValue.toUpperCase()) !==
                                        -1
                                      );
                                    }}
                                  />
                                  <Popconfirm
                                    title="Êtes-vous sur de vouloir supprimer la ligne?"
                                    onConfirm={() =>
                                      setNewEmployes(
                                        newEmployes.filter(
                                          (e) => employe._id !== e._id
                                        )
                                      )
                                    }>
                                    <Button
                                      icon={
                                        <DeleteOutlined
                                          style={{ color: 'red' }}
                                        />
                                      }
                                      type="link"
                                    />
                                  </Popconfirm>
                                </div>
                              </Form.Item>
                            )}
                          </Form.Item>
                        ) : (
                          employe.nom
                        )}
                      </Col>
                      <Col span={2}>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, curValues) => {
                            return (
                              prevValues['employes'][employe._id]['absent'] !==
                              curValues['employes'][employe._id]['absent']
                            );
                          }}>
                          {({ getFieldValue }) => (
                            <Form.Item
                              style={{ marginBottom: 0 }}
                              name={['employes', employe._id, 'perdiem']}>
                              <Input
                                style={{ height: 34, textAlign: 'center' }}
                                disabled={getFieldValue([
                                  'employes',
                                  employe._id,
                                  'absent',
                                ])}
                              />
                            </Form.Item>
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={3} style={{ textAlign: 'center' }}>
                        <CustomFormItem
                          name={['employes', employe._id, 'debut']}
                          type="debut"
                          employe={employe}
                          form={form}
                        />
                      </Col>
                      <Col span={3}>
                        <CustomFormItem
                          name={['employes', employe._id, 'fin']}
                          type="fin"
                          employe={employe}
                          form={form}
                        />
                      </Col>
                      <Col span={3}>
                        <CustomFormItem
                          name={['employes', employe._id, 'repas']}
                          type="repas"
                          employe={employe}
                        />
                      </Col>
                      <Col span={3}>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, curValues) => {
                            return (
                              prevValues['employes'][employe._id]['absent'] !==
                              curValues['employes'][employe._id]['absent']
                            );
                          }}>
                          {({ getFieldValue }) => (
                            <Form.Item
                              noStyle
                              name={['employes', employe._id, 'total']}>
                              <Input
                                readOnly
                                style={{ height: 34, textAlign: 'center' }}
                                disabled={getFieldValue([
                                  'employes',
                                  employe._id,
                                  'absent',
                                ])}
                              />
                            </Form.Item>
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={2} style={{ textAlign: 'center' }}>
                        <Form.Item
                          noStyle
                          valuePropName="checked"
                          name={['employes', employe._id, 'absent']}>
                          <Checkbox
                            onChange={(e) => {
                              resetLine(employe._id);
                              form.validateFields();
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </List.Item>
                );
              }}
            />
            <div style={{ width: '100%' }}>
              <Button
                type="primary"
                style={{ marginTop: 10 }}
                icon={<PlusOutlined />}
                onClick={handleAddMaindoeuvre}>
                Ajouter un employé
              </Button>
            </div>
          </Card>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: 10,
            }}>
            <Form.Item noStyle style={{ textAlign: 'center' }}>
              <Button
                type="primary"
                htmlType="submit"
                icon={ressourcesSaved && <CheckOutlined />}
                disabled={feuilleDeRoute.status !== 'complete'}
                style={{
                  backgroundColor: ressourcesSaved && 'green',
                  borderColor: ressourcesSaved && 'green',
                }}>
                {ressourcesSaved ? 'Enregistré' : 'Enregistrer'}
              </Button>
            </Form.Item>
          </div>
        </Space>
      ) : (
        <Spin />
      )}
    </Form>
  );
};
export default Ressources;
