import {
  Card,
  Form,
  Input,
  Space,
  Button,
  InputNumber,
  Collapse,
  Row,
  Col,
  Select,
} from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { memo, useState, useEffect } from "react";

const Gabarits = ({ form, disabled }) => {
  return (
    <Card
      title="Gabarits"
      headStyle={{
        backgroundColor: "#001529",
        height: 50,
        display: "flex",
        alignItems: "center",
        color: "white",
      }}
      bodyStyle={{
        paddingTop: 15,
        paddingBottom: 15,
        display: "flex",
        flexDirection: "column",
      }}>
      <Form.Item
        label="Nombre de gabarits"
        name="gabarits"
        rules={[{ required: true, message: "À remplir" }]}>
        <InputNumber
          style={{ width: "100%" }}
          min={0}
          autoComplete="new-password"
        />
      </Form.Item>
    </Card>
  );
};

export default memo(Gabarits);
