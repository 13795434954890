import { useEffect, useState, useContext, useRef } from "react";
import { Context } from "../utils/Context";
import dayjs from "dayjs";
import {
  getdbfields,
  getrapportfeuillesderoute,
  getfeuillederoute,
  getnodonneur,
} from "../modules/api";
import { generateMultiplePDF } from "../modules/pdf";
import {
  Table as AntTable,
  Tabs,
  Space,
  Input,
  Button,
  Tooltip,
  Tag,
  Card,
  Select,
  DatePicker,
  Form,
  Typography,
  Dropdown,
} from "antd";
import {
  FaFile,
  FaSearch,
  FaCheck,
  FaHammer,
  FaPaintRoller,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { PrinterOutlined, FileExcelOutlined } from "@ant-design/icons";
import xlsx from "json-as-xlsx";

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const { Text } = Typography;

const I18nStatut = (status) => {
  switch (status) {
    case "todo":
      return "À faire";
    case "complete":
      return "À Approuver";
    case "approved":
      return "Approuvée";
  }
};

const columns = [
  {
    title: "Date",
    dataIndex: "date",
    render: (el) => dayjs(el).format("YYYY-MM-DD"),
  },
  {
    title: "Chargé de projet",
    dataIndex: "chargedeprojet",
  },
  {
    title: "Client",
    dataIndex: "client",
  },
  {
    title: "Projet",
    dataIndex: "noprojet",
    render: (text, record) => (
      <div>
        <div>{record.noprojet}</div>
        <div>{record.descriptionprojet}</div>
        <div>{record.descriptionphase}</div>
      </div>
    ),
  },
  {
    title: "Contremaître",
    dataIndex: "contremaitre",
  },
  {
    title: "Statut",
    dataIndex: "status",
    render: (text) => {
      switch (text) {
        case "todo":
          return (
            <Tag icon={<FaHammer style={{ marginRight: 5 }} />} color="default">
              À faire
            </Tag>
          );
        case "complete":
          return (
            <Tag icon={<FaSearch style={{ marginRight: 5 }} />} color="blue">
              À approuver
            </Tag>
          );
        case "approved":
          return (
            <Tag icon={<FaCheck style={{ marginRight: 5 }} />} color="success">
              Approuvée
            </Tag>
          );
      }
    },
  },
];

const TopTabContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const xlsxInfoGenerales = (feuillesDeRoute) => {
  const data = [
    {
      sheet: "Feuilles De Routes",
      columns: [
        {
          label: "Date",
          value: (row) => dayjs(row.date, "YYYYMMDD").format("YYYY-MM-DD"),
        },
        { label: "Client", value: "client" },
        {
          label: "Chargé de projets",
          value: "chargedeprojet",
        },
        { label: "Contremaitre", value: "contremaitre" },
        { label: "No Projet", value: "noprojet" },
        {
          label: "Description",
          value: (row) => row.descriptionprojet + " - " + row.descriptionphase,
        },
        {
          label: "Statut",
          value: (row) => I18nStatut(row.status),
        },
        { label: "Localisation", value: "localisation" },
        { label: "No Client", value: "numeroclient" },
        {
          label: "Heure Arrivée Chantier Prévue",
          value: "heurearriveechantier",
        },
        {
          label: "Commentaires Bureau",
          value: "commentaires",
        },
        {
          label: "Commentaires Chantier",
          value: "commentaireschantier",
        },
        {
          label: "Travaux Complétés?",
          value: "travauxcompletes",
        },
        {
          label: "Heure Arrivée Chantier Réelle",
          value: "heurereellearriveechantier",
        },
        {
          label: "Heure Départ Chantier Réelle",
          value: "heurereelledepartchantier",
        },
        {
          label: "Température",
          value: "temperature",
        },
        {
          label: "Météo",
          value: "meteo",
        },
        {
          label: "Humidité chaussée",
          value: "chausseehumiditee",
        },
        {
          label: "Propreté chaussée",
          value: "chausseeproprete",
        },
        {
          label: "Nom du surveillant",
          value: "nomclientchantier",
        },
        {
          label: "Numéro du surveillant",
          value: "numclientchantier",
        },
        {
          label: "Courriel du surveillant",
          value: "emailclientchantier",
        },
      ],
      content: feuillesDeRoute,
    },
  ];
  // console.log({ data });
  const settings = {
    fileName: dayjs().unix() + "-general", // Name of the resulting spreadsheet
    // extraLength: 2, // A bigger number means that columns will be wider
    writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
  };
  xlsx(data, settings);
};

const xlsxInfoPeinture = (feuillesDeRoute, feuillesDeRoutePeinture) => {
  const dataToPrint = [];
  feuillesDeRoutePeinture.forEach((feuilleDeRoutePeinture) => {
    const feuilleDeRoute = feuillesDeRoute.find(
      (e) => e.id === feuilleDeRoutePeinture.idfeuillederoute
    );
    const date = feuilleDeRoute.date;
    const noprojet = feuilleDeRoute.noprojet;
    const description =
      feuilleDeRoute.descriptionprojet +
      " - " +
      feuilleDeRoute.descriptionphase;
    const statut = I18nStatut(feuilleDeRoute.status);
    if (Number(feuilleDeRoutePeinture.cd_chaudiere_blanc_total) > 0) {
      dataToPrint.push({
        date,
        noprojet,
        description,
        statut,
        article: "MD_CHAUD_BLANC",
        type: "Blanc",
        total: Number(feuilleDeRoutePeinture.cd_chaudiere_blanc_total),
      });
    }
    if (Number(feuilleDeRoutePeinture.cd_chaudiere_jaune_total) > 0) {
      dataToPrint.push({
        date,
        noprojet,
        description,
        statut,
        article: "MD_CHAUD_JAUNE",
        type: "Jaune",
        total: Number(feuilleDeRoutePeinture.cd_chaudiere_jaune_total),
      });
    }
    feuilleDeRoutePeinture.cd_chaudiere_couleurspeciale?.forEach((e) =>
      dataToPrint.push({
        date,
        noprojet,
        description,
        statut,
        article: "CD_CHAUD_SPECIAL",
        type: e.couleur,
        start_g: Number(e.start ?? 0),
        finish_g: Number(e.finish ?? 0),
        total_g: Number(e.total ?? 0),
        total: Number(e.total ?? 0),
      })
    );
    feuilleDeRoutePeinture.cd_barril_blanc?.forEach((e) =>
      dataToPrint.push({
        date,
        noprojet,
        description,
        statut,
        article: "CD_BAR_BLANC",
        type: "Blanc",
        start_g: Number(e.start ?? 0),
        finish_g: Number(e.finish ?? 0),
        total_g: Number(e.total ?? 0),
        total: Number(e.total ?? 0),
      })
    );
    feuilleDeRoutePeinture.cd_barril_jaune?.forEach((e) =>
      dataToPrint.push({
        date,
        noprojet,
        description,
        statut,
        article: "CD_BAR_JAUNE",
        type: "Jaune",
        start_g: Number(e.start ?? 0),
        finish_g: Number(e.finish ?? 0),
        total_g: Number(e.total ?? 0),
        total: Number(e.total ?? 0),
      })
    );
    feuilleDeRoutePeinture.cd_barril_bille?.forEach((e) =>
      dataToPrint.push({
        date,
        noprojet,
        description,
        statut,
        article: "CD_BAR_BILLE",
        type: "Bille",
        start_g: Number(e.start ?? 0),
        finish_g: Number(e.finish ?? 0),
        total_g: Number(e.total ?? 0),
        total: Number(e.total ?? 0),
      })
    );
    feuilleDeRoutePeinture.cd_reservoir_blanc?.forEach((e) =>
      dataToPrint.push({
        date,
        noprojet,
        description,
        statut,
        article: "CD_RES_BLANC",
        type: "Blanc",
        start_g: Number(e.start ?? 0),
        finish_g: Number(e.finish ?? 0),
        total_g: Number(e.total ?? 0),
        total: Number(e.total ?? 0),
      })
    );
    feuilleDeRoutePeinture.cd_reservoir_jaune?.forEach((e) =>
      dataToPrint.push({
        date,
        noprojet,
        description,
        statut,
        article: "CD_RES_JAUNE",
        type: "Jaune",
        start_g: Number(e.start ?? 0),
        finish_g: Number(e.finish ?? 0),
        total_g: Number(e.total ?? 0),
        total: Number(e.total ?? 0),
      })
    );
    feuilleDeRoutePeinture.cd_reservoir_bille?.forEach((e) =>
      dataToPrint.push({
        date,
        noprojet,
        description,
        statut,
        article: "CD_RES_BILLE",
        type: "Bille",
        start_g: Number(e.start ?? 0),
        finish_g: Number(e.finish ?? 0),
        total_g: Number(e.total ?? 0),
        total: Number(e.total ?? 0),
      })
    );
    if (Number(feuilleDeRoutePeinture.md_reservoir_blanc_total) > 0) {
      dataToPrint.push({
        date,
        noprojet,
        description,
        statut,
        article: "MD_RES_BLANC",
        type: "Blanc",
        total: Number(feuilleDeRoutePeinture.md_reservoir_blanc_total),
      });
    }
    if (Number(feuilleDeRoutePeinture.md_reservoir_jaune_total) > 0) {
      dataToPrint.push({
        date,
        noprojet,
        description,
        statut,
        article: "MD_RES_JAUNE",
        type: "Jaune",
        total: Number(feuilleDeRoutePeinture.md_reservoir_jaune_total),
      });
    }
    if (Number(feuilleDeRoutePeinture.md_reservoir_durcisseur_total) > 0) {
      dataToPrint.push({
        date,
        noprojet,
        description,
        statut,
        article: "MD_RES_DURCISSEUR",
        type: "Durcisseur",
        total: Number(feuilleDeRoutePeinture.md_reservoir_durcisseur_total),
      });
    }
    feuilleDeRoutePeinture.md_reservoir_bille?.forEach((e) =>
      dataToPrint.push({
        date,
        noprojet,
        description,
        statut,
        article: "MD_RES_BILLE",
        type: "Bille",
        start_g: Number(e.depart_gauche ?? 0),
        finish_g: Number(e.arrivee_gauche ?? 0),
        total_g: Number(e.total_gauche ?? 0),
        start_d: Number(e.depart_droite ?? 0),
        finish_d: Number(e.arrivee_droite ?? 0),
        total_d: Number(e.total_droite ?? 0),
        total: Number(e.total_gauche ?? 0) + Number(e.total_droite ?? 0),
      })
    );
    feuilleDeRoutePeinture.md_reservoir_couleurspeciale?.forEach((e) =>
      dataToPrint.push({
        date,
        noprojet,
        description,
        statut,
        article: "MD_RES_SPECIAL",
        type: e.couleur,
        start_g: Number(e.depart_gauche ?? 0),
        finish_g: Number(e.arrivee_gauche ?? 0),
        total_g: Number(e.total_gauche ?? 0),
        start_d: Number(e.depart_droite ?? 0),
        finish_d: Number(e.arrivee_droite ?? 0),
        total_d: Number(e.total_droite ?? 0),
        total: Number(e.total_gauche ?? 0) + Number(e.total_droite ?? 0),
      })
    );
    feuilleDeRoutePeinture.mma?.forEach((e) =>
      dataToPrint.push({
        date,
        noprojet,
        description,
        statut,
        article: "MMA",
        type: e.couleur,
        start_g: Number(e.composant_A_start ?? 0),
        finish_g: Number(e.composant_A_finish ?? 0),
        total_g: Number(e.composant_A_total ?? 0),
        start_d: Number(e.composant_B_start ?? 0),
        finish_d: Number(e.composant_B_finish ?? 0),
        total_d: Number(e.composant_B_total ?? 0),
      })
    );
    if (Number(feuilleDeRoutePeinture.gabarits) > 0) {
      dataToPrint.push({
        date,
        noprojet,
        description,
        statut,
        article: "GABARIT",
        type: "Gabarit",
        total: Number(feuilleDeRoutePeinture.gabarits),
      });
    }
  });
  const data = [
    {
      sheet: "Peinture",
      columns: [
        {
          label: "Date",
          value: (row) => dayjs(row.date, "YYYYMMDD").format("YYYY-MM-DD"),
        },
        { label: "No Projet", value: "noprojet" },
        {
          label: "Description",
          value: "description",
        },
        {
          label: "Article",
          value: "article",
        },
        {
          label: "Type",
          value: "type",
        },
        {
          label: "Statut",
          value: (row) => I18nStatut(row.status),
        },
        {
          label: "Depart G",
          value: "start_g",
        },
        {
          label: "Arrivée G",
          value: "finish_g",
        },
        {
          label: "Total G",
          value: "total_g",
        },
        {
          label: "Depart D",
          value: "start_d",
        },
        {
          label: "Arrivée D",
          value: "finish_d",
        },
        {
          label: "Total D",
          value: "total_d",
        },
        {
          label: "Total",
          value: "total",
        },
      ],
      content: dataToPrint,
    },
  ];
  // console.log({ data });
  const settings = {
    fileName: dayjs().unix() + "-peinture", // Name of the resulting spreadsheet
    // extraLength: 2, // A bigger number means that columns will be wider
    writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
  };
  xlsx(data, settings);
};

const Table = ({ feuillesDeRoute, searchLoading }) => {
  const navigate = useNavigate();

  return (
    <AntTable
      columns={columns}
      rowKey={(record) => record._id}
      dataSource={feuillesDeRoute}
      pagination={false}
      loading={searchLoading}
      rowClassName="clickable-row"
      onRow={(record, rowIndex) => {
        return {
          onClick: () => {
            if (record.status === "todo") {
              navigate(
                `/feuillederoute/${record.date}/${record.phase}/creation`
              );
            } else {
              navigate(`/feuillederoute/${record.date}/${record.phase}/detail`);
            }
          },
        };
      }}
    />
  );
};

const RapportsPage = () => {
  const context = useContext(Context);
  const { setHeaderType } = context;
  const [fields, setFields] = useState({
    chargedeprojets: [],
    contremaitres: [],
    clients: [],
    noprojets: [],
  });
  const [searchLoading, setSearchLoading] = useState(false);
  const [printLoading, setPrintLoading] = useState(false);
  const [feuillesDeRoute, setFeuillesDeRoute] = useState([]);
  const [feuillesDeRoutePeinture, setFeuillesDeRoutePeinture] = useState([]);
  useEffect(() => {
    setHeaderType("none");
    getdbfields().then((result) => {
      setFields(result);
    });
  }, []);
  const onFinish = (values) => {
    console.log("Success:", values);
    setSearchLoading(true);
    getrapportfeuillesderoute(values).then((result) => {
      console.log({ result });
      setFeuillesDeRoute(result.feuillesDeRoute);
      setFeuillesDeRoutePeinture(result.feuillesDeRoutePeinture);
      setSearchLoading(false);
    });
  };

  return (
    <div className="RapportsPage">
      <div className="RapportsPageContainer">
        <Card style={{ marginBottom: 15 }}>
          <Form
            style={{ display: "flex", flexWrap: "wrap", gap: 5 }}
            onFinish={onFinish}>
            <Form.Item noStyle name="daterange">
              <RangePicker />
            </Form.Item>
            <Form.Item noStyle name="chargesdeprojets">
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "200px",
                }}
                dropdownMatchSelectWidth={false}
                placeholder="Chargés de projets"
                options={fields.chargedeprojets.map((e) => {
                  return { label: e, value: e };
                })}
              />
            </Form.Item>
            <Form.Item noStyle name="clients">
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "200px",
                }}
                dropdownMatchSelectWidth={false}
                placeholder="Clients"
                options={fields.clients.map((e) => {
                  return { label: e, value: e };
                })}
              />
            </Form.Item>
            <Form.Item noStyle name="noprojets">
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "200px",
                }}
                dropdownMatchSelectWidth={false}
                placeholder="No de projets"
                options={fields.noprojets.map((e) => {
                  return { label: e, value: e };
                })}
              />
            </Form.Item>
            <Form.Item noStyle name="contremaitres">
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "200px",
                }}
                dropdownMatchSelectWidth={false}
                placeholder="Contremaitres"
                options={fields.contremaitres.map((e) => {
                  return { label: e, value: e };
                })}
              />
            </Form.Item>
            <Form.Item noStyle name="statuts">
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "200px",
                }}
                dropdownMatchSelectWidth={false}
                placeholder="Statuts"
                options={[
                  { label: "À faire", value: "todo" },
                  { label: "À approuver", value: "complete" },
                  { label: "Approuvée", value: "approved" },
                ]}
              />
            </Form.Item>
            <Button htmlType="submit" type="primary">
              Rechercher
            </Button>
          </Form>
        </Card>
        <TopTabContainer>
          <Space>
            <Text>Nombre de feuilles de route:</Text>
            <Text strong>{feuillesDeRoute.length}</Text>
          </Space>
          <Space>
            <Dropdown
              menu={{
                items: [
                  {
                    label: (
                      <div onClick={() => xlsxInfoGenerales(feuillesDeRoute)}>
                        Général
                      </div>
                    ),
                    key: 0,
                  },
                  {
                    label: (
                      <div
                        onClick={() =>
                          xlsxInfoPeinture(
                            feuillesDeRoute,
                            feuillesDeRoutePeinture
                          )
                        }>
                        Peinture
                      </div>
                    ),
                    key: 1,
                  },
                ],
              }}
              trigger={["click"]}>
              <Button
                icon={<FileExcelOutlined />}
                disabled={!feuillesDeRoute.length}
                onClick={(e) => {
                  e.preventDefault();
                }}>
                XLSX
              </Button>
            </Dropdown>
            <Button
              type="danger"
              icon={<PrinterOutlined />}
              loading={printLoading}
              disabled={!feuillesDeRoute.length}
              onClick={async () => {
                setPrintLoading(true);
                const feuilles = await Promise.all(
                  feuillesDeRoute.map(async (feuille) => {
                    const data = await getfeuillederoute(
                      `${feuille.date}-${feuille.phase}`
                    );
                    // console.log({ data });
                    const nodonneur = await getnodonneur(feuille.noprojet);
                    return {
                      feuilleDeRoute: data.feuilleDeRoute,
                      feuilleDeRouteQuantites: data.feuilleDeRouteQuantites,
                      feuilleDeRoutePeinture: data.feuilleDeRoutePeinture,
                      feuilleDeRouteMainDoeuvre: data.feuilleDeRouteMainDoeuvre,
                      feuilleDeRouteVehicule: data.feuilleDeRouteVehicule,
                      noDonneur: nodonneur.msg[0]?.NoDonneur,
                    };
                  })
                );
                await generateMultiplePDF(feuilles);
                setPrintLoading(false);
              }}>
              PDF
            </Button>
          </Space>
        </TopTabContainer>
        <Table
          feuillesDeRoute={feuillesDeRoute}
          searchLoading={searchLoading}
        />
        {/* <Tabs hideAdd type="card" defaultActiveKey="feuillesderoute">
          <TabPane
            closable={false}
            tab={
              <Space>
                <FaFile />
                <span>Feuilles de route</span>
              </Space>
            }
            key="feuillesderoute">
            
          </TabPane>
        </Tabs> */}
      </div>
    </div>
  );
};

export default RapportsPage;
