import { useEffect, useState, useContext, useRef } from "react";
import {
  getfeuillederoute,
  sendfeuillederoute,
  getemployeslist,
  changestatusfeuillederoute,
  getnodonneur,
} from "../modules/api";
import { useParams } from "react-router-dom";
import { Context } from "../utils/Context";
import moment from "moment";
import { Tabs, Space, Badge, Select, notification, Button } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import Resume from "../components/Resume";
import Ressources from "../components/Ressources";
import Quantites from "../components/Quantites";
import Peinture from "../components/Peinture";
import {
  MdLibraryBooks,
  MdLocalShipping,
  MdOutlineTag,
  MdFormatPaint,
} from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import { generateFeuilleDeRoutePDF } from "../modules/pdf";

const { TabPane } = Tabs;
const { Option } = Select;

const FeuilleDeRouteDetailPage = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [employes, setEmployes] = useState([]);
  const [vehicules, setVehicules] = useState([]);
  const [client, setClient] = useState(null);
  const { phaseid, date } = useParams();
  const [feuilleDeRoute, setFeuilleDeRoute] = useState({});
  const [feuilleDeRouteQuantites, setFeuilleDeRouteQuantites] = useState({});
  const [feuilleDeRouteMainDoeuvres, setFeuilleDeRouteMainDoeuvres] = useState(
    {}
  );
  const [feuilleDeRouteVehicules, setFeuilleDeRouteVehicules] = useState({});
  const [feuilleDeRoutePeinture, setFeuilleDeRoutePeinture] = useState({});
  const [ressourcesSaved, setRessourcesSaved] = useState(false);
  let [quantitesSaved, setQuantitesSaved] = useState(false);
  let [peintureSaved, setPeintureSaved] = useState(false);
  const [status, setStatus] = useState("");
  const feuilleDeRouteToSend = useRef();
  const [employesList, setEmployesList] = useState([]);
  const [noDonneur, setNoDonneur] = useState(undefined);
  const context = useContext(Context);
  const { setHeaderType, setHeaderTitle, setHeaderLoading } = context;
  useEffect(() => {
    setHeaderType("feuillederoute");
    getFeuilleDeRoute();
    getEmployesList();
  }, []);
  const getFeuilleDeRoute = async () => {
    setLoading(true);
    const response = await getfeuillederoute(`${date}-${phaseid}`);
    setFeuilleDeRoute(response.feuilleDeRoute);
    setFeuilleDeRouteQuantites(response.feuilleDeRouteQuantites);
    setFeuilleDeRouteMainDoeuvres(response.feuilleDeRouteMainDoeuvre);
    setFeuilleDeRouteVehicules(response.feuilleDeRouteVehicule);
    setFeuilleDeRoutePeinture(response.feuilleDeRoutePeinture);
    setStatus(response.feuilleDeRoute.status);
    getNoDonneur(response.feuilleDeRoute.noprojet);
    setHeaderTitle(
      `${response.feuilleDeRoute.noprojet} - ${
        response.feuilleDeRoute.descriptionprojet
      } - ${moment(date).format("dddd DD MMMM YYYY")} - ${
        response.feuilleDeRoute.descriptionphase
      }`
    );
    setLoading(false);
    feuilleDeRouteToSend.current = {};
    setRessourcesSaved(false);
    setQuantitesSaved(false);
    setPeintureSaved(false);
    console.log(response);
  };
  const saveFeuilleDeRouteData = (categorie, value) => {
    // console.log({ categorie, value });
    if (categorie === "ressources") {
      setRessourcesSaved(value ? true : false);
    } else if (categorie === "quantites") {
      setQuantitesSaved(value ? true : false);
    } else if (categorie === "peinture") {
      setPeintureSaved(value ? true : false);
    }
    feuilleDeRouteToSend.current = {
      ...feuilleDeRouteToSend.current,
      [categorie]: value,
    };
  };
  const getEmployesList = async () => {
    const employesList = await getemployeslist();
    if (employesList.success) setEmployesList(employesList.msg);
  };
  const getNoDonneur = async (noprojet) => {
    const response = await getnodonneur(noprojet);
    setNoDonneur(response.msg && response.msg[0]?.NoDonneur);
  };
  return (
    <div className="FeuilleDeRoutePage">
      <div className="FeuilleDeRouteContainer">
        <div
          style={{
            maxWidth: 1500,
            // backgroundColor: 'white',
            width: "100%",
            padding: 20,
          }}
        >
          <Tabs
            defaultActiveKey="all"
            tabBarStyle={{ backgroundColor: "white", padding: "0px 20px" }}
            tabBarExtraContent={
              <Space>
                <Select
                  loading={loading}
                  value={status}
                  disabled={(() => {
                    if (feuilleDeRoute.status === "approved") {
                      return false;
                    }
                    return !(
                      ressourcesSaved &&
                      quantitesSaved &&
                      peintureSaved
                    );
                  })()}
                  onChange={async (value) => {
                    if (value !== status) {
                      setLoading(true);
                      try {
                        let response;
                        if (status !== "approved") {
                          response = await sendfeuillederoute({
                            ...feuilleDeRouteToSend.current,
                            id: feuilleDeRoute.id,
                            status: value,
                          });
                        } else {
                          response = await changestatusfeuillederoute({
                            id: feuilleDeRoute.id,
                            status: value,
                          });
                        }
                        if (response.success) {
                          notification.success({
                            message: "Changement de statut complété!",
                            placement: "top",
                          });
                          getFeuilleDeRoute();
                        } else {
                          notification.error({
                            message: response.error,
                            placement: "top",
                          });
                        }
                      } catch (err) {
                        notification.error({
                          message: err.message,
                          placement: "top",
                        });
                      }
                      setLoading(false);
                    }
                  }}
                  style={{
                    width: 120,
                  }}
                >
                  <Option value="todo">À faire</Option>
                  <Option value="complete">À approuver</Option>
                  <Option value="approved">Approuvée</Option>
                </Select>
                <Button
                  type="danger"
                  icon={<PrinterOutlined />}
                  disabled={feuilleDeRoute.status !== "approved"}
                  onClick={() => {
                    generateFeuilleDeRoutePDF(
                      feuilleDeRoute,
                      feuilleDeRouteQuantites,
                      feuilleDeRoutePeinture,
                      feuilleDeRouteMainDoeuvres,
                      feuilleDeRouteVehicules,
                      noDonneur
                    );
                  }}
                >
                  Imprimer
                </Button>
              </Space>
            }
          >
            <TabPane
              tab={
                <Space>
                  <MdLibraryBooks />
                  Résumé
                </Space>
              }
              key="resume"
            >
              <Resume
                feuilleDeRoute={feuilleDeRoute}
                feuilleDeRouteMainDoeuvres={feuilleDeRouteMainDoeuvres}
                feuilleDeRouteVehicules={feuilleDeRouteVehicules}
                loading={loading}
              />
            </TabPane>
            <TabPane
              tab={
                <Badge
                  count={
                    ressourcesSaved ? (
                      <FaCheckCircle style={{ color: "green" }} />
                    ) : (
                      0
                    )
                  }
                  offset={[0, -5]}
                >
                  <Space>
                    <MdLocalShipping />
                    Ressources
                  </Space>
                </Badge>
              }
              key="ressources"
            >
              <Ressources
                loading={loading}
                feuilleDeRoute={feuilleDeRoute}
                feuilleDeRouteVehicules={feuilleDeRouteVehicules}
                feuilleDeRouteMainDoeuvres={feuilleDeRouteMainDoeuvres}
                saveFeuilleDeRouteData={saveFeuilleDeRouteData}
                ressourcesSaved={ressourcesSaved}
                employesList={employesList}
              />
            </TabPane>
            <TabPane
              tab={
                <Badge
                  count={
                    quantitesSaved ? (
                      <FaCheckCircle style={{ color: "green" }} />
                    ) : (
                      0
                    )
                  }
                  offset={[0, -5]}
                >
                  <Space>
                    <MdOutlineTag />
                    Quantités
                  </Space>
                </Badge>
              }
              key="quantites"
            >
              <Quantites
                loading={loading}
                feuilleDeRoute={feuilleDeRoute}
                feuilleDeRouteQuantites={feuilleDeRouteQuantites}
                saveFeuilleDeRouteData={saveFeuilleDeRouteData}
                quantitesSaved={quantitesSaved}
              />
            </TabPane>
            <TabPane
              tab={
                <Badge
                  count={
                    peintureSaved ? (
                      <FaCheckCircle style={{ color: "green" }} />
                    ) : (
                      0
                    )
                  }
                  offset={[0, -5]}
                >
                  <Space>
                    <MdFormatPaint />
                    Peinture
                  </Space>
                </Badge>
              }
              key="peinture"
            >
              <Peinture
                loading={loading}
                feuilleDeRoute={feuilleDeRoute}
                feuilleDeRouteQuantites={feuilleDeRouteQuantites}
                feuilleDeRoutePeinture={feuilleDeRoutePeinture}
                saveFeuilleDeRouteData={saveFeuilleDeRouteData}
                peintureSaved={peintureSaved}
              />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default FeuilleDeRouteDetailPage;
