import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./App.less";
import reportWebVitals from "./reportWebVitals";
import { ConfigProvider, Result, Button } from "antd";
import frFR from "antd/lib/locale/fr_FR";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Layout from "./Navigation/Layout";
import CalendarPage from "./pages/calendarPage";
import PlanificationPage from "./pages/planificationPage";
import FeuilleDeRouteCreationPage from "./pages/feuillederoutecreationPage";
import FeuilleDeRouteDetailPage from "./pages/feuillederoutedetailPage";
import FeuillesDeRoutePage from "./pages/feuillesderoutePage";
import UtilisateursPage from "./pages/utilisateursPage";
import LoginPage from "./pages/loginPage";
import RapportsPage from "./pages/rapportsPage";
import HeuresPage from "./pages/heuresPage";
import moment from "moment";
import "moment/locale/fr-ca";
import ContextProvider from "./utils/Context";

moment.locale("fr-ca");
ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={frFR}>
      <ContextProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route
                path="/"
                element={
                  <Navigate
                    replace
                    to={`calendrier/${moment().format("YYYYMMDD")}`}
                  />
                }
              />
              <Route path="calendrier/:date" element={<CalendarPage />} />
              <Route
                path="planification/:date"
                element={<PlanificationPage />}
              />
              <Route
                path="feuillederoute/:date/:phaseid/creation"
                element={<FeuilleDeRouteCreationPage />}
              />
              <Route
                path="feuillederoute/:date/:phaseid/detail"
                element={<FeuilleDeRouteDetailPage />}
              />
              <Route path="feuillesderoute" element={<FeuillesDeRoutePage />} />
              <Route path="utilisateurs" element={<UtilisateursPage />} />
              <Route path="rapports" element={<RapportsPage />} />
              <Route path="heures" element={<HeuresPage />} />
              <Route path="login" element={<LoginPage />} />
              <Route
                path="*"
                element={
                  <Result
                    status="404"
                    title="404"
                    subTitle="La page que vous voulez visiter n'existe pas."
                    // extra={<Button type="primary">Back Home</Button>}
                  />
                }
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </ContextProvider>
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
