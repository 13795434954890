import { useState, useEffect } from 'react';
import {
  Card,
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  AutoComplete,
  Space,
} from 'antd';
import {
  FaMapMarkerAlt,
  FaUserAlt,
  FaPhoneAlt,
  FaClock,
  FaComments,
  FaBook,
} from 'react-icons/fa';
import MaskedInput from 'antd-mask-input';
import UploadFile from './UploadFile';
const { TextArea } = Input;
const Informations = ({
  informations,
  setInformations,
  data,
  selectedFichiers,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(informations.current);
  }, [informations.current]);
  return (
    <Card
      title="Informations"
      type="inner"
      headStyle={{ backgroundColor: '#001529', color: '#fff' }}>
      <Form
        form={form}
        name="informations"
        layout="vertical"
        onFieldsChange={(changedFields, allFields) => {
          setInformations(changedFields[0].name[0], changedFields[0].value);
        }}>
        <Form.Item
          name="localisation"
          label={
            <Space align="center">
              <FaMapMarkerAlt />
              Localisation
            </Space>
          }>
          <Input />
        </Form.Item>
        <Form.Item
          name="nomclient"
          label={
            <Space align="center">
              <FaUserAlt />
              Nom du client
            </Space>
          }>
          <Input />
        </Form.Item>
        <Form.Item
          name="numeroclient"
          label={
            <Space align="center">
              <FaPhoneAlt />
              Téléphone du client
            </Space>
          }>
          <Input />
        </Form.Item>
        <Form.Item
          name="heurearriveechantier"
          label={
            <Space align="center">
              <FaClock />
              Heure d'arrivée au chantier
            </Space>
          }>
          <Input />
        </Form.Item>
        <Form.Item
          name="commentaires"
          label={
            <Space align="center">
              <FaComments />
              Commentaires
            </Space>
          }>
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item
          name="documents"
          label={
            <Space align="center">
              <FaBook />
              Documents
            </Space>
          }>
          <UploadFile
            data={data}
            informations={informations}
            setInformations={setInformations}
            selectedFichiers={selectedFichiers}
          />
        </Form.Item>
      </Form>
    </Card>
  );
};

export default Informations;
