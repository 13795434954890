import '../App.less';
import moment from 'moment';
import 'moment/locale/fr-ca';
import { Layout } from 'antd';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useEffect, useContext } from 'react';
import { Context } from '../utils/Context';
import Header from './Header';
import Sider from './Sider';
const { Content } = Layout;

const MyLayout = () => {
  moment.locale('fr-ca');
  const context = useContext(Context);
  const { loggedUser, getContextUser } = context;
  const { pathname } = useLocation();
  useEffect(() => {
    if (!loggedUser) {
      getContextUser();
    }
  }, [pathname]);
  return (
    <Layout
      hasSider
      style={{
        height: '100vh',
      }}>
      <Sider />
      <Layout
        style={{
          height: '100vh',
          overflowY: 'hidden',
        }}>
        <Header />
        <Content style={{ overflowY: 'auto' }}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default MyLayout;
