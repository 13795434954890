import { useEffect, useState, useContext, useRef } from "react";
import { Context } from "../utils/Context";
import {
  getAllUsers,
  createUser,
  deleteUser,
  modifyPassword,
  loginUser,
  getUser,
} from "../modules/users";
import { getemployeslist } from "../modules/api";
import {
  Typography,
  Collapse,
  Avatar,
  List,
  Form,
  Button,
  Input,
  Select,
  Row,
  Col,
  Tag,
  Spin,
  Menu,
  Dropdown,
  Modal,
  Space,
} from "antd";
import dayjs from "dayjs";
import { stringToColour } from "../modules/utils";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone"); // dependent on utc plugin
var relativeTime = require("dayjs/plugin/relativeTime");
require("dayjs/locale/fr-ca");

const { Title } = Typography;
const { Panel } = Collapse;
const { Option } = Select;
const UtilisateursPage = () => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(relativeTime);
  dayjs.tz.setDefault("America/Toronto");
  dayjs.locale("fr-ca");
  const context = useContext(Context);
  const { setHeaderType, successNotif, errorNotif, loggedUser } = context;
  const [users, setUsers] = useState([]);
  const [employes, setEmployes] = useState([]);
  const [form] = Form.useForm();
  const [formModify] = Form.useForm();
  const [newUserLoading, setNewUserLoading] = useState(false);
  const [getUsersLoading, setGetUsersLoading] = useState(false);
  const [modifyPasswordLoading, setModifyPasswordLoading] = useState(false);
  const [currentEmploye, setCurrentEmploye] = useState({});
  const [modifyPasswordModalOpen, setModifyPasswordModalOpen] = useState(false);

  useEffect(() => {
    setHeaderType("utilisateurs");
    _getAllUsers();
    _getEmployesList();
    // createUser({
    //   employe: { username: 'test', Nom: 'Test', Prénom: 'Test' },
    //   password: 'test',
    //   groupe: 'Master',
    // });
  }, []);
  const _getAllUsers = async () => {
    setGetUsersLoading(true);
    const response = await getAllUsers();
    if (response.success) {
      let users = response.users.sort((a, b) =>
        a.lastname?.localeCompare(b.lastname)
      );
      users = users.filter((user) => {
        return user.groupe !== "Master";
      });
      setUsers(users);
    } else {
      errorNotif(response.error);
    }
    setGetUsersLoading(false);
  };
  const _getEmployesList = async () => {
    let response = await getemployeslist();
    if (response.success) {
      const employes = response.msg
        .sort((a, b) => a.Nom.localeCompare(b.Nom))
        .map((employe) => {
          return {
            ...employe,
            username: (employe.Prénom[0] + employe.Nom)
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .replaceAll(" ", "")
              .replaceAll("-", "")
              .toLowerCase(),
          };
        });
      console.log({ employes });
      setEmployes(employes);
    } else {
      errorNotif(response.error);
    }
  };
  const onFinish = async (values) => {
    const { employe, password, groupe } = values;
    const _employe = employes.filter((e) => e.username === employe)[0];
    setNewUserLoading(true);
    const response = await createUser({ employe: _employe, password, groupe });
    if (response.success) {
      successNotif("L'employé a été ajouté!");
      _getAllUsers();
      form.resetFields();
    } else {
      errorNotif(response.error);
    }
    setNewUserLoading(false);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinishModify = async (values) => {
    console.log("Success:", values);
    setModifyPasswordLoading(true);
    const response = await modifyPassword({
      username: currentEmploye.username,
      password: values.password,
    });
    if (response.success) {
      successNotif("Le mot de passe a bien été modifié");
      formModify.resetFields();
      setModifyPasswordModalOpen(false);
      setCurrentEmploye({});
    } else {
      errorNotif(response.error);
    }
    setModifyPasswordLoading(false);
  };
  const onFinishFailedModify = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const handleFormModifySubmit = () => {
    formModify
      .validateFields()
      .then((values) => {
        onFinishModify(values);
      })
      .catch((errorInfo) => {
        onFinishFailedModify(errorInfo);
      });
  };

  const menu = (item) => (
    <Menu
      items={[
        {
          label: "Modifier le mot de passe",
          icon: <EditOutlined />,
          key: "1",
          onClick: () => {
            setModifyPasswordModalOpen(true);
            setCurrentEmploye(item);
          },
        },
        {
          label: "Supprimer l'utilisateur",
          danger: true,
          icon: <DeleteOutlined />,
          key: "2",
          onClick: () => {
            Modal.confirm({
              centered: true,
              title: `Êtes-vous certain de vouloir supprimer ${
                item.lastname + ", " + item.firstname
              }?`,
              cancelText: "Annuler",
              onCancel: () => {},
              onOk: async () => {
                const response = await deleteUser({ username: item.username });
                if (response.success) {
                  successNotif(
                    `${
                      item.lastname + ", " + item.firstname
                    } a bien été supprimé!`
                  );
                  _getAllUsers();
                } else {
                  errorNotif(response.error);
                }
              },
            });
          },
        },
      ]}
    />
  );
  return (
    <div className="UtilisateursPage">
      <div className="UtilisateursPageContainer">
        <Collapse style={{ marginBottom: 20 }}>
          <Panel header="Créer un nouvel utilisateur">
            <Form
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              scrollToFirstError={{ behavior: "smooth" }}
              form={form}
            >
              <Form.Item
                label="Employé"
                name="employe"
                rules={[
                  {
                    required: true,
                    message: "Veuillez choisir un employé!",
                  },
                ]}
              >
                <Select placeholder="Sélectionnez un employé" allowClear>
                  {employes
                    .filter(
                      (employe) =>
                        !users.find(
                          (u) =>
                            u.lastname.trim() === employe.Nom.trim() &&
                            u.firstname.trim() === employe.Prénom.trim()
                        )
                    )
                    .map((employe) => (
                      <Option value={employe.username} key={employe.username}>
                        {employe.Nom}, {employe.Prénom}
                      </Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Mot de passe"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Veuillez saisir un mot de passe!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Groupe"
                name="groupe"
                rules={[
                  {
                    required: true,
                    message: "Veuillez choisir un groupe!",
                  },
                ]}
              >
                <Select placeholder="Sélectionnez un groupe" allowClear>
                  <Option value="Chantier">Chantier</Option>
                  <Option value="Bureau">Bureau</Option>
                  <Option value="Comptabilité">Comptabilité</Option>
                  <Option value="Administrateur">Administrateur</Option>
                </Select>
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={newUserLoading}
                >
                  Sauvegarder
                </Button>
              </Form.Item>
            </Form>
          </Panel>
        </Collapse>

        <Title level={5}>Tous les utilisateurs</Title>
        {getUsersLoading ? (
          <div style={{ textAlign: "center" }}>
            <Spin />
          </div>
        ) : (
          <List
            itemLayout="horizontal"
            dataSource={users}
            header={
              <Row style={{ width: "100%", fontWeight: "bold" }} gutter={15}>
                <Col span={2} />
                <Col span={8}>Nom</Col>
                <Col span={4}>Identifiant</Col>
                <Col span={4}>Dernière connexion</Col>
                <Col span={4}>Groupe</Col>
                <Col span={2} />
              </Row>
            }
            renderItem={(item) => (
              <List.Item>
                <Row style={{ width: "100%" }} gutter={15} align="middle">
                  <Col span={2}>
                    <Avatar
                      style={{
                        backgroundColor: stringToColour(item.username),
                      }}
                    >
                      {item.lastname[0].toUpperCase() +
                        item.firstname[0].toUpperCase()}
                    </Avatar>
                  </Col>
                  <Col span={8}>{item.lastname + ", " + item.firstname}</Col>
                  <Col span={4}>{item.username}</Col>
                  <Col span={4}>
                    {item.lastlogin
                      ? dayjs(item.lastlogin)
                          .tz()
                          .fromNow()
                          .charAt(0)
                          .toUpperCase() +
                        dayjs(item.lastlogin).tz().fromNow().slice(1)
                      : "Jamais"}
                  </Col>
                  <Col span={4}>
                    <Tag color={stringToColour(item.groupe)}>{item.groupe}</Tag>
                  </Col>
                  <Col span={2}>
                    <Dropdown.Button overlay={menu(item)} trigger={["click"]} />
                  </Col>
                </Row>
              </List.Item>
            )}
          />
        )}
      </div>
      <Modal
        visible={modifyPasswordModalOpen}
        title={`Modifier le mot de passe de ${
          currentEmploye.lastname + ", " + currentEmploye.firstname
        }`}
        centered
        onCancel={() => {
          formModify.resetFields();
          setModifyPasswordModalOpen(false);
        }}
        footer={
          <Space>
            <Button
              onClick={() => {
                formModify.resetFields();
                setModifyPasswordModalOpen(false);
              }}
            >
              Annuler
            </Button>
            <Button
              type="primary"
              loading={modifyPasswordLoading}
              onClick={() => {
                handleFormModifySubmit();
              }}
            >
              OK
            </Button>
          </Space>
        }
      >
        <Form
          labelCol={{
            span: 12,
          }}
          wrapperCol={{
            span: 12,
          }}
          onFinish={onFinishModify}
          onFinishFailed={onFinishFailedModify}
          autoComplete="off"
          scrollToFirstError={{ behavior: "smooth" }}
          form={formModify}
        >
          <Form.Item
            label="Nouveau mot de passe"
            name="password"
            rules={[
              {
                required: true,
                message: "Veuillez indiquer un mot de passe!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default UtilisateursPage;
