import { useParams, Link, useNavigate } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import {
  getplaniffromdate,
  getclientsfromnoproject,
  getfeuillesderoute,
} from '../modules/api';
import {
  FaTruck,
  FaHammer,
  FaUser,
  FaLuggageCart,
  FaArrowLeft,
  FaStar,
} from 'react-icons/fa';
import { GiSteeringWheel } from 'react-icons/gi';
import { CheckOutlined } from '@ant-design/icons';
import Ressource from '../components/Ressource';
import moment from 'moment';
import {
  Card,
  List,
  Typography,
  Divider,
  Spin,
  Space,
  Col,
  Row,
  Affix,
  Empty,
  BackTop,
  Button,
  DatePicker,
} from 'antd';
import { Context } from '../utils/Context';
const { Title } = Typography;
const PlanificationPage = () => {
  let params = useParams();
  let navigate = useNavigate();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState({});
  const [container, setContainer] = useState(null);
  const [feuillesDeRoute, setFeuillesDeRoute] = useState([]);
  const context = useContext(Context);
  const { setHeaderType } = context;
  useEffect(() => {
    getData();
  }, [params]);
  const getData = async () => {
    setLoading(true);
    let response = await getplaniffromdate(params.date);
    setData(response);
    console.log(response);
    let projectList = [];
    Object.values(response).map((projects) => {
      projects.map((project) => {
        projectList.push(project.NoProjet);
      });
    });
    response = await getclientsfromnoproject(JSON.stringify(projectList));
    setClients(response);
    // console.log(response);
    response = await getfeuillesderoute(params.date);
    setFeuillesDeRoute(response.feuillesDeRoute);
    // console.log(response);
    setLoading(false);
  };
  useEffect(() => {
    setHeaderType('planification');
  }, []);
  return (
    <div className="PlanificationPage">
      <div className="PlanificationPageContainer" ref={setContainer}>
        {container && <BackTop target={() => container} />}
        <Spin spinning={loading} style={{ marginTop: 50 }}>
          {loading === false && Object.keys(data).length === 0 && (
            <Empty style={{ marginTop: 10 }} />
          )}
          <Space direction="vertical" style={{ width: '100%' }}>
            {Object.keys(data)
              .sort()
              .map((chargedeprojet) => (
                <Card
                  key={chargedeprojet}
                  title={
                    <Affix target={() => container}>
                      <div
                        style={{
                          backgroundColor: '#001529',
                          width: '100%',
                          height: 50,
                          padding: '0px 24px',
                          display: 'flex',
                          alignItems: 'center',
                          color: 'white',
                        }}>
                        {chargedeprojet}
                      </div>
                    </Affix>
                  }
                  headStyle={{
                    padding: 0,
                  }}
                  bodyStyle={{
                    padding: 0,
                    backgroundColor: '#f0f0f0',
                  }}>
                  <Space
                    direction="vertical"
                    style={{ width: '100%', padding: 10 }}>
                    {data[chargedeprojet]
                      .sort((a, b) => a.NoProjet.localeCompare(b.NoProjet))
                      .map((phase) => (
                        <Card
                          key={phase.KpProjPhase}
                          title={
                            <Space size={3}>
                              <span>{phase.NoProjet}</span>
                              <span>-</span>
                              <span>{phase.DsProjet}</span>
                              <span>-</span>
                              <span style={{ fontWeight: 'normal' }}>
                                {phase.DsPhase}
                              </span>
                              <span style={{ fontWeight: 'normal' }}>
                                {clients[phase.NoProjet]
                                  ? '(' + clients[phase.NoProjet] + ')'
                                  : ''}
                              </span>
                            </Space>
                          }
                          type="inner"
                          extra={
                            <Button
                              type="primary"
                              href={`../feuillederoute/${params.date}/${phase.KpProjPhase}/creation/`}
                              icon={
                                feuillesDeRoute.filter(
                                  (feuilleDeRoute) =>
                                    feuilleDeRoute.phase == phase.KpProjPhase
                                ).length > 0 && <CheckOutlined />
                              }
                              style={{
                                backgroundColor:
                                  feuillesDeRoute.filter(
                                    (feuilleDeRoute) =>
                                      feuilleDeRoute.phase == phase.KpProjPhase
                                  ).length > 0 && 'green',
                                borderColor:
                                  feuillesDeRoute.filter(
                                    (feuilleDeRoute) =>
                                      feuilleDeRoute.phase == phase.KpProjPhase
                                  ).length > 0 && 'green',
                              }}>
                              Feuille de route
                            </Button>
                          }>
                          <Row align="middle">
                            <Col span={4}>
                              <div
                                style={{
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                }}>
                                Camion
                              </div>
                            </Col>
                            <Col span={4}>
                              <div
                                style={{
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                }}>
                                Remorque
                              </div>
                            </Col>
                            <Col span={6}>
                              <div
                                style={{
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                }}>
                                Employé
                              </div>
                            </Col>
                            <Col span={4}>
                              <div
                                style={{
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                }}>
                                Équipement
                              </div>
                            </Col>
                            <Col span={3}>
                              <div
                                style={{
                                  textAlign: 'center',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                }}>
                                Départ
                              </div>
                            </Col>
                            <Col span={3}>
                              <div
                                style={{
                                  textAlign: 'center',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                }}>
                                Remarque
                              </div>
                            </Col>
                          </Row>
                          <Divider style={{ marginBottom: 0 }} />
                          <List
                            dataSource={phase.PProjPhasePlanif}
                            renderItem={(line) => (
                              <List.Item>
                                <Row style={{ width: '100%' }} align="middle">
                                  <Col span={4}>
                                    <Ressource
                                      icon={<FaTruck />}
                                      title={line.Camion}
                                    />
                                  </Col>
                                  <Col span={4}>
                                    {line.Ressource.Remorque.map((remorque) => (
                                      <Ressource
                                        key={remorque.CLEUNIK}
                                        icon={<FaLuggageCart />}
                                        title={remorque.Nom}
                                      />
                                    ))}
                                  </Col>
                                  <Col span={6}>
                                    {line.Ressource.Employe.map((employe) => (
                                      <Ressource
                                        key={employe.CLEUNIK}
                                        icon={<FaUser />}
                                        rightIcon={
                                          <>
                                            {employe.FlContremaitre ? (
                                              <FaStar />
                                            ) : null}
                                            {employe.FLPRINCIPALE ? (
                                              <GiSteeringWheel />
                                            ) : null}
                                          </>
                                        }
                                        title={employe.Nom}
                                      />
                                    ))}
                                  </Col>
                                  <Col span={4}>
                                    {line.Ressource.Radio.map((radio) => (
                                      <Ressource
                                        key={radio.CLEUNIK}
                                        icon={<FaHammer />}
                                        title={radio.Nom}
                                      />
                                    ))}
                                  </Col>
                                  <Col span={3}>
                                    <div style={{ textAlign: 'center' }}>
                                      {line.DEPART}
                                    </div>
                                  </Col>
                                  <Col span={3}>
                                    <div style={{ textAlign: 'center' }}>
                                      {line.Remarque}
                                    </div>
                                  </Col>
                                </Row>
                              </List.Item>
                            )}
                          />
                        </Card>
                      ))}
                  </Space>
                </Card>
              ))}
          </Space>
        </Spin>
      </div>
    </div>
  );
};

export default PlanificationPage;
