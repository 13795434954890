import { Card, Form, Input, Space, Button, InputNumber, Row, Col } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { memo, useState, useEffect } from 'react';
const MMA = () => {
  return (
    <Card
      title="MMA"
      headStyle={{
        backgroundColor: '#001529',
        height: 50,
        display: 'flex',
        alignItems: 'center',
        color: 'white',
      }}
      bodyStyle={{
        paddingTop: 15,
        paddingBottom: 15,
        display: 'flex',
        flexDirection: 'column',
      }}>
      <Form.List name="mma">
        {(fields, { add, remove }, { errors }) => (
          <Card
            title="Couleur"
            extra={
              <Button
                icon={<PlusOutlined />}
                type="primary"
                onClick={() => add()}
              />
            }
            headStyle={{
              backgroundColor: '#54b86f',
              color: 'white',
            }}
            style={{ flex: 1 }}
            bodyStyle={{
              padding: 1,
            }}>
            <Row>
              {fields.map((field, index) => (
                <Col span={24} gutter={[15, 15]} key={field.key}>
                  <Card
                    title={`Couleur #${index + 1}`}
                    extra={
                      <Button
                        icon={<MinusOutlined />}
                        type="danger"
                        onClick={() => remove(field.name)}
                      />
                    }
                    bordered={false}
                    key={field.key}>
                    <Form.Item
                      label="Couleur"
                      name={[field.name, 'couleur']}
                      rules={[{ required: true, message: 'À remplir' }]}>
                      <Input />
                    </Form.Item>
                    <Row gutter={15}>
                      <Col span={12}>
                        <Card
                          title="Componsant A"
                          style={{ flex: 1 }}
                          headStyle={{
                            backgroundColor: '#ffffff',
                            color: 'black',
                            height: 50,
                            display: 'flex',
                            alignItems: 'center',
                          }}>
                          <Form.Item
                            label="Nb départ"
                            name={[field.name, 'composant_A_start']}>
                            <InputNumber style={{ width: '100%' }} min={0} />
                          </Form.Item>
                          <Form.Item
                            label="Nb arrivée"
                            name={[field.name, 'composant_A_finish']}>
                            <InputNumber style={{ width: '100%' }} min={0} />
                          </Form.Item>
                          <Form.Item
                            label="Total"
                            name={[field.name, 'composant_A_total']}>
                            <InputNumber style={{ width: '100%' }} min={0} />
                          </Form.Item>
                        </Card>
                      </Col>
                      <Col span={12}>
                        <Card
                          title="Componsant B"
                          style={{ flex: 1 }}
                          headStyle={{
                            backgroundColor: '#94928d',
                            color: 'white',
                            height: 50,
                            display: 'flex',
                            alignItems: 'center',
                          }}>
                          <Form.Item
                            label="Nb départ"
                            name={[field.name, 'composant_B_start']}>
                            <InputNumber style={{ width: '100%' }} min={0} />
                          </Form.Item>
                          <Form.Item
                            label="Nb arrivée"
                            name={[field.name, 'composant_B_finish']}>
                            <InputNumber style={{ width: '100%' }} min={0} />
                          </Form.Item>
                          <Form.Item
                            label="Total"
                            name={[field.name, 'composant_B_total']}>
                            <InputNumber style={{ width: '100%' }} min={0} />
                          </Form.Item>
                        </Card>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ))}
            </Row>
          </Card>
        )}
      </Form.List>
    </Card>
  );
};

export default memo(MMA);
