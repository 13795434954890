import styled from 'styled-components';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { Button, Layout, Typography, DatePicker, Space, Spin } from 'antd';
import { Context } from '../utils/Context';
import { useContext, useEffect } from 'react';
import moment from 'moment';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
const { Title } = Typography;
const { Header } = Layout;
const Container = styled.div`
  background-color: #001529;
  display: flex;
  height: 50px;
  position: fixed;
  width: 100%;
  align-items: center;
  padding: 0px 20px;
  color: white;
  font-size: 16px;
  z-index: 5;
`;
const LeftMenu = styled.div`
  flex: 0;
`;
const MiddleMenu = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const RightMenu = styled.div`
  flex: 0;
  text-align: right;
`;
const CustomHeader = ({ type, params, headerTitle, headerLoading }) => {
  let navigate = useNavigate();
  switch (type) {
    case 'calendar':
      return (
        <>
          <LeftMenu></LeftMenu>
          <MiddleMenu>
            <Space>
              <Button
                icon={<LeftOutlined />}
                type="primary"
                onClick={() => {
                  navigate(
                    `/calendrier/${moment(params.date)
                      .clone()
                      .subtract(1, 'month')
                      .format('YYYYMMDD')}`,
                    { replace: true }
                  );
                }}
              />
              <Title style={{ margin: 0, width: 300, textAlign: 'center' }}>
                {moment(params.date).format('MMMM YYYY')}
              </Title>
              <Button
                type="primary"
                icon={<RightOutlined />}
                onClick={() => {
                  navigate(
                    `/calendrier/${moment(params.date)
                      .clone()
                      .add(1, 'month')
                      .format('YYYYMMDD')}`,
                    { replace: true }
                  );
                }}
              />
              <DatePicker
                picker="month"
                inputReadOnly
                value={''}
                style={{ width: 175 }}
                onChange={(date, dateString) => {
                  navigate(
                    `/calendrier/${moment(date).clone().format('YYYYMMDD')}`,
                    {
                      replace: true,
                    }
                  );
                }}
              />
              <Button
                type="link"
                onClick={() => {
                  navigate(
                    `/calendrier/${moment().clone().format('YYYYMMDD')}`,
                    {
                      replace: true,
                    }
                  );
                }}>
                Aujourd'hui
              </Button>
            </Space>
          </MiddleMenu>
          <RightMenu></RightMenu>
        </>
      );
    case 'planification':
      return (
        <>
          <LeftMenu></LeftMenu>
          <MiddleMenu>
            <Space>
              <Button
                type="primary"
                icon={<LeftOutlined />}
                onClick={() => {
                  navigate(
                    '/planification/' +
                      moment(params.date).subtract(1, 'day').format('YYYYMMDD'),
                    { replace: true }
                  );
                }}
              />
              <Title
                level={3}
                style={{
                  margin: 0,
                  width: 350,
                  textAlign: 'center',
                }}>
                {moment(params.date).format('dddd DD MMMM YYYY')}
              </Title>
              <Button
                type="primary"
                icon={<RightOutlined />}
                onClick={() => {
                  navigate(
                    '/planification/' +
                      moment(params.date).add(1, 'day').format('YYYYMMDD'),
                    { replace: true }
                  );
                }}
              />
              <DatePicker
                picker="date"
                inputReadOnly
                value={''}
                style={{ width: 180 }}
                onChange={(date, dateString) => {
                  navigate('/planification/' + date.format('YYYYMMDD'), {
                    replace: true,
                  });
                }}
              />
              <Button
                type="link"
                onClick={() => {
                  navigate('/planification/' + moment().format('YYYYMMDD'), {
                    replace: true,
                  });
                }}>
                Aujourd'hui
              </Button>
            </Space>
          </MiddleMenu>
          <RightMenu></RightMenu>
        </>
      );
    case 'feuillederoute':
      return (
        <>
          <LeftMenu></LeftMenu>
          <MiddleMenu>
            <Space
              style={{
                flex: 1,
                justifyContent: 'center',
              }}>
              <Title
                level={5}
                style={{
                  margin: 0,
                  // width: 350,
                  textAlign: 'center',
                }}>
                {headerLoading ? <Spin /> : headerTitle}
              </Title>
            </Space>
          </MiddleMenu>
          <RightMenu></RightMenu>
        </>
      );
    default:
      return null;
  }
};
const MyHeader = () => {
  const context = useContext(Context);
  const { headerType, headerTitle, headerLoading } = context;
  const location = useLocation();
  const params = useParams();
  const noHeader = () => {
    if (
      headerType === 'feuillesderoute' ||
      headerType === 'utilisateurs' ||
      headerType === 'rapports' ||
      headerType === 'login' ||
      headerType === 'none'
    )
      return true;
    return false;
  };
  return (
    <Header
      style={{
        backgroundColor: '#f0f0f0',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        // position: 'fixed',
        // opacity: 0.5,
        padding: '0px 15px',
        zIndex: 5,
        height: noHeader() ? 0 : 50,
      }}>
      <CustomHeader
        type={headerType}
        params={params}
        headerTitle={headerTitle}
        headerLoading={headerLoading}
      />
    </Header>
  );
};

export default MyHeader;
