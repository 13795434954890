import { useState, useEffect } from 'react';
import {
  Card,
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  AutoComplete,
  Space,
} from 'antd';
import {
  DeleteOutlined,
  PlusOutlined,
  DollarOutlined,
} from '@ant-design/icons';
import { FaHashtag, FaDollarSign } from 'react-icons/fa';
import NumberFormat from 'react-number-format';
const dataSource = [
  {
    _id: '0',
  },
  {
    _id: '1',
  },
  {
    _id: '2',
  },
];
const renderItem = (title, quantity, price, ligne) => ({
  value: title,
  label: (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}>
      <div style={{ overflowWrap: 'break-word' }}>
        {ligne} - {title}
      </div>
      {title && (
        <Space>
          <span>
            <FaHashtag />
            <NumberFormat
              value={quantity}
              displayType="text"
              thousandSeparator={' '}
              decimalSeparator=","
            />
          </span>
          <span>
            <FaDollarSign />
            <NumberFormat
              value={price}
              displayType="text"
              thousandSeparator={' '}
              decimalSeparator=","
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </span>
        </Space>
      )}
    </div>
  ),
  key: ligne,
});
const countSource = dataSource.length;
const MMA = ({ commandeVente, mmaData, setMMAData }) => {
  const [options, setOptions] = useState([]);
  const [count, setCount] = useState(countSource);
  useEffect(() => {
    let _options = commandeVente.map((item) =>
      renderItem(item.Description, item.Quantité, item.Prix, item.Ligne)
    );
    setOptions([{ options: _options, label: 'Bordereau' }]);
  }, [commandeVente]);
  useEffect(() => {
    if (!mmaData.length) {
      setMMAData([...dataSource]);
    }
  }, [mmaData]);
  const handleDelete = (key) => {
    const _data = [...mmaData];
    setMMAData(_data.filter((item) => item._id !== key));
  };
  const handleAdd = () => {
    const newData = {
      key: count,
    };
    setMMAData([...mmaData, newData]);
    setCount((count) => count + 1);
  };
  const onAutoCompleteChange = (key, index) => (e) => {
    const newData = [...mmaData];
    newData[index][key] = e;
    setMMAData(newData);
  };
  const onInputChange = (key, index) => (e) => {
    const newData = [...mmaData];
    newData[index][key] = e.target.value;
    setMMAData(newData);
  };
  const columns = [
    {
      title: 'Description',
      dataIndex: 'description',
      width: '50%',
      render: (text, record, index) => (
        <AutoComplete
          style={{ width: '100%' }}
          value={text}
          onChange={onAutoCompleteChange('description', index)}
          options={options}
          dropdownMatchSelectWidth={false}
          allowClear
          filterOption={(inputValue, option) => {
            if (!option.value) return false;
            return (
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            );
          }}
        />
      ),
    },
    {
      title: 'Unité',
      dataIndex: 'unite',
      width: '10%',
      render: (text, record, index) => (
        <Input
          value={text}
          onChange={onInputChange('unite', index)}
          style={{ textAlign: 'center' }}
        />
      ),
      align: 'center',
    },
    {
      title: 'Quantités estimées',
      children: [
        {
          title: 'Jaune',
          dataIndex: 'jauneest',
          render: (text, record, index) => (
            <Input
              value={text}
              onChange={onInputChange('jauneest', index)}
              style={{ textAlign: 'center' }}
            />
          ),
          align: 'center',
        },
        {
          title: 'Blanc',
          dataIndex: 'blancest',
          render: (text, record, index) => (
            <Input
              value={text}
              onChange={onInputChange('blancest', index)}
              style={{ textAlign: 'center' }}
            />
          ),
          align: 'center',
        },
        {
          title: 'Skip',
          dataIndex: 'skipest',
          render: (text, record, index) => (
            <Input
              value={text}
              onChange={onInputChange('skipest', index)}
              style={{ textAlign: 'center' }}
            />
          ),
          align: 'center',
        },
      ],
    },
    {
      title: 'Supprimer?',
      dataIndex: 'supprimer',
      align: 'center',
      render: (_, record) =>
        mmaData.length >= 1 ? (
          <Popconfirm
            title="Êtes-vous sur de vouloir supprimer la ligne?"
            onConfirm={() => handleDelete(record._id)}>
            <Button icon={<DeleteOutlined />} type="link"></Button>
          </Popconfirm>
        ) : null,
    },
  ];
  return (
    <Card
      title="MMA"
      type="inner"
      headStyle={{ backgroundColor: '#001529', color: '#fff' }}>
      <Table
        pagination={false}
        bordered
        dataSource={mmaData}
        columns={columns}
        rowKey="_id"
      />
      <Button
        type="primary"
        style={{ marginTop: 10 }}
        icon={<PlusOutlined />}
        onClick={handleAdd}>
        Ajouter une ligne
      </Button>
    </Card>
  );
};

export default MMA;
