import { useEffect, useState, useContext, useRef } from "react";
import { Context } from "../utils/Context";
import {
  getallfeuillesderoute,
  getfilteredfeuillesderoute,
  getsearchedfeuillesderoute,
} from "../modules/api";
import {
  Table as AntTable,
  Tabs,
  Space,
  Input,
  Button,
  Tooltip,
  Tag,
} from "antd";
import dayjs from "dayjs";
import {
  InfoCircleOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { FaFile, FaSearch, FaCheck, FaHammer } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
const columns = [
  {
    title: "Date",
    dataIndex: "date",
    render: (el) => dayjs(el).format("YYYY-MM-DD"),
    width: 120,
  },
  {
    title: "Chargé de projet",
    dataIndex: "chargedeprojet",
  },
  {
    title: "Client",
    dataIndex: "client",
    width: 250,
  },
  {
    title: "Projet",
    dataIndex: "noprojet",
    render: (text, record) => (
      <div>
        <div>{record.noprojet}</div>
        <div>{record.descriptionprojet}</div>
        <div>{record.descriptionphase}</div>
        <div style={{ display: "flex", gap: 5, flexWrap: "wrap" }}>
          {record.vehicules.map((vehicule, i) => (
            <Tag key={i} style={{ margin: 0 }}>
              {`${vehicule.nom.split(" ")[0]} ${
                vehicule.remarque ? `- ${vehicule.remarque}` : ""
              }`}
            </Tag>
          ))}
        </div>
      </div>
    ),
  },
  {
    title: "Contremaître",
    dataIndex: "contremaitre",
  },
  {
    title: "Statut",
    dataIndex: "status",
    render: (text) => {
      switch (text) {
        case "todo":
          return (
            <Tag icon={<FaHammer style={{ marginRight: 5 }} />} color="default">
              À faire
            </Tag>
          );
        case "complete":
          return (
            <Tag icon={<FaSearch style={{ marginRight: 5 }} />} color="blue">
              À approuver
            </Tag>
          );
        case "approved":
          return (
            <Tag icon={<FaCheck style={{ marginRight: 5 }} />} color="success">
              Approuvée
            </Tag>
          );
      }
    },
  },
];
const { TabPane } = Tabs;

const Table = ({ tab }) => {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 1,
  });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleTableChange = (newPagination) => {
    fetchData(newPagination.pageSize, newPagination.current, tab);
  };
  useEffect(() => {
    fetchData(pagination.pageSize, pagination.current, tab);
  }, []);

  const fetchData = async (quantity, page, tab) => {
    let response;
    if (tab === "all") {
      setLoading(true);
      response = await getallfeuillesderoute(quantity, page);
      console.log({ response });
      if (response.success) {
        setData(response.feuillesDeRoute);
        setPagination({
          current: page,
          pageSize: quantity,
          total: response.count,
          showTotal: (total) => `Total ${total} feuilles de routes`,
        });
      }
      setLoading(false);
    } else if (tab === "todo" || tab === "complete" || tab === "approved") {
      setLoading(true);
      response = await getfilteredfeuillesderoute(quantity, page, tab);
      if (response.success) {
        setData(response.feuillesDeRoute);
        setPagination({
          current: page,
          pageSize: quantity,
          total: response.count,
          showTotal: (total) => `Total ${total} feuilles de routes`,
        });
      }
      setLoading(false);
    } else {
      setLoading(true);
      response = await getsearchedfeuillesderoute(quantity, page, tab);
      if (response.success) {
        setData(response.feuillesDeRoute);
        setPagination({
          current: page,
          pageSize: quantity,
          total: response.count,
          showTotal: (total) => `Total ${total} feuilles de routes`,
        });
      }
      setLoading(false);
    }
  };
  return (
    <AntTable
      columns={columns}
      rowKey={(record) => record._id}
      dataSource={data}
      pagination={pagination}
      loading={loading}
      onChange={handleTableChange}
      rowClassName="clickable-row"
      onRow={(record, rowIndex) => {
        return {
          onClick: () => {
            if (record.status === "todo") {
              // navigate(
              //   `/feuillederoute/${record.date}/${record.phase}/creation`
              // );
              window.open(
                `${window.location.origin}/feuillederoute/${record.date}/${record.phase}/creation`
              );
            } else {
              // navigate(`/feuillederoute/${record.date}/${record.phase}/detail`);
              window.open(
                `${window.location.origin}/feuillederoute/${record.date}/${record.phase}/detail`
              );
            }
          },
        };
      }}
    />
  );
};

const FeuillesDeRoute = () => {
  const context = useContext(Context);
  const { setHeaderType } = context;
  const [searchInfo, setSearchInfo] = useState("");
  const [searchedTabs, setSearchedTabs] = useState([]);
  const [activeTab, setActiveTab] = useState("all");
  const inputRef = useRef(null);

  useEffect(() => {
    setHeaderType("feuillesderoute");
  }, []);
  const onTabChange = (key) => {
    setActiveTab(key);
  };
  const add = (search) => {
    setSearchedTabs([...searchedTabs, search]);
    setActiveTab(search);
  };
  const remove = (targetKey) => {
    const targetIndex = searchedTabs.findIndex((pane) => pane === targetKey);
    const newPanes = searchedTabs.filter((pane) => pane !== targetKey);
    if (activeTab === targetKey) {
      if (targetIndex > 0) {
        setActiveTab(searchedTabs[targetIndex - 1]);
      } else if (searchedTabs.length > 0) {
        setActiveTab(searchedTabs[1]);
      } else {
        setActiveTab("all");
      }
    }
    setSearchedTabs(newPanes);
  };
  const onEdit = (targetKey, action) => {
    if (action === "add") {
      add();
    } else {
      remove(targetKey);
    }
  };
  const search = async (searchInput) => {
    if (searchedTabs.includes(searchInput)) {
      setActiveTab(searchInput);
    } else {
      add(searchInput);
    }
    setSearchInfo("");
    inputRef.current.blur();
  };
  return (
    <div className="FeuillesDeRoutePage">
      <div className="FeuillesDeRoutePageContainer">
        <Tabs
          hideAdd
          onEdit={onEdit}
          activeKey={activeTab}
          onChange={onTabChange}
          type="editable-card"
          defaultActiveKey="all"
          tabBarExtraContent={
            <Space>
              <Input
                allowClear
                ref={inputRef}
                value={searchInfo}
                onChange={(e) => {
                  setSearchInfo(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    search(searchInfo);
                  }
                }}
              />
              <Button
                icon={<FaSearch style={{ fontSize: 14 }} />}
                type="primary"
                onClick={() => {
                  search(searchInfo);
                }}
              />
              <Tooltip
                placement="left"
                title={
                  <div>
                    <div>Date : YYYYMMDD</div>
                    <div>Projet : XX-XXX</div>
                  </div>
                }
              >
                <InfoCircleOutlined />
              </Tooltip>
            </Space>
          }
        >
          <TabPane
            closable={false}
            tab={
              <Space>
                <FaFile />
                <span>Toutes les feuilles</span>
              </Space>
            }
            key="all"
          >
            <Table tab="all" />
          </TabPane>
          <TabPane
            closable={false}
            tab={
              <Space>
                <FaHammer />
                <span>À faire</span>
              </Space>
            }
            key="todo"
          >
            <Table tab="todo" />
          </TabPane>
          <TabPane
            closable={false}
            tab={
              <Space>
                <FaSearch />
                <span>À approuver</span>
              </Space>
            }
            key="complete"
          >
            <Table tab="complete" />
          </TabPane>
          <TabPane
            closable={false}
            tab={
              <Space>
                <FaCheck />
                <span>Approuvées</span>
              </Space>
            }
            key="approved"
          >
            <Table tab="approved" />
          </TabPane>
          {searchedTabs.map((searchedTab) => (
            <TabPane tab={searchedTab} key={searchedTab}>
              <Table tab={searchedTab} />
            </TabPane>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default FeuillesDeRoute;
